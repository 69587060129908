import React from 'react'
import './LocationsMap.sass'
import GoogleMap from 'google-map-react'
import MapMarker from './MapMarker/MapMarker'

function Map({ center, defaultCenter, zoom = 9, points = [] }) {
  const config = {
    defaultCenter,
    center,
    zoom,
    draggable: true,
  }

  return (
    <div className="LocationsMap">
      <GoogleMap
        bootstrapURLKeys={{
          key: 'AIzaSyA0BDTlJTQRjmEpEfGr9uvSnAOt-qF_rSs',
          language: 'uk',
        }}
        {...config}
      >
        {points.map((p) => (
          <MapMarker {...p} {...p.coords} />
        ))}
      </GoogleMap>
    </div>
  )
}

export default Map
