import React, { useEffect } from 'react'
import './Orders.sass'
import TabGroup from '../../../ui/TabGroup/TabGroup'
import EmptyArea from '../../../ui/EmptyArea/EmptyArea'
import Button from '../../../ui/Button/Button'
import OrdersTable from './OrdersTable/OrdersTable'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../App'
import { db } from '../../../config/firebase'
import { isEmpty, isNull, find, cloneDeep } from 'lodash'
import Spinner from '../../../ui/Spinner/Spinner'
import { Route, withRouter } from 'react-router-dom'
import OrderData from './OrderData/OrderData'
import Media from 'react-media'
import OrdersList from './OrdersList/OrdersList'

function Orders({ ...router }) {
  const { user, location, category } = useContext(AuthContext)

  const [orders, setOrders] = useState(null)
  const [tabs, setTabs] = useState([
    {
      id: 'active',
      title: 'Активні',
      statuses: ['KGpVTDMZlJxyxn9zenpy', 'mb0oZEnvz1eqUZ6FAY8m'],
      isActive: true,
      counter: 0,
      theme: 'primary',
    },
    {
      id: 'past',
      title: 'Минулі',
      statuses: ['e2QJKCyNal7CmLhFVOsZ', 'WCZpA64uC6lSVwnP24xq'],
      isActive: false,
      counter: 0,
      theme: 'internal-link',
    },
  ])

  useEffect(() => {
    const ordersListener = db
      .collection('orders')
      .where('uid', '==', user.uid)
      .onSnapshot((snap) => {
        const data = []
        snap.forEach((d) => data.push({ docId: d.id, ...d.data() }))
        setOrders(data)
        setTabs(
          tabs.map((t) => {
            if (t.id === 'active') {
              t.counter = data.filter((o) =>
                t.statuses.includes(o.status)
              ).length
            }
            if (t.id === 'past') {
              t.counter = data.filter((o) =>
                t.statuses.includes(o.status)
              ).length
            }

            return t
          })
        )
      })
    return () => {
      ordersListener()
    }
  }, [])

  const onTabClicked = (id) => {
    const tabsClone = cloneDeep(tabs)
    setTabs(
      tabsClone.map((t) => {
        t.isActive = t.id === id
        return t
      })
    )
  }

  const activeTab = find(tabs, ['isActive', true])

  const addMore = () => {
    if (location.id && category.id) {
      router.history.push('/catalog')
    } else {
      router.history.push('/')
    }
  }

  return (
    <>
      <Route path="/profile/orders/:orderId" exact component={OrderData} />
      <Route
        path="/profile/orders"
        exact
        render={() => (
          <div className="Orders">
            <h2>Мої замовлення</h2>
            {isNull(orders) ? (
              <div className="Spinner-Container">
                <Spinner type="button" theme="gray" />
              </div>
            ) : isEmpty(orders) ? (
              <>
                <EmptyArea
                  content={
                    <span className="Caption-Regular">
                      У вас ще немає замовлень
                    </span>
                  }
                />
                <div className="Buttons">
                  <Button
                    title="Обрати спорядження"
                    size={40}
                    onClick={addMore}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="Orders-Head">
                  <TabGroup tabs={tabs} onClick={onTabClicked} />
                  {/* <SortingTabs /> */}
                </div>
                <Media query={{ maxWidth: 1366 }}>
                  {(matches) =>
                    matches ? (
                      <OrdersList
                        orders={orders.filter((o) =>
                          activeTab.statuses.includes(o.status)
                        )}
                      />
                    ) : (
                      <OrdersTable
                        orders={orders.filter((o) =>
                          activeTab.statuses.includes(o.status)
                        )}
                      />
                    )
                  }
                </Media>
              </>
            )}
          </div>
        )}
      />
    </>
  )
}

export default withRouter(Orders)
