import { functions } from '../../../config/firebase'
import getOnlyNumbers from '../../../utils/forms/getOnlyNumbers'

const sendOllAppOrderSms = ({ orderId, sum }) => {
  const func = functions.httpsCallable('serviceSmsOnCall')
  const message = `Нове замовлення #${orderId} на сумму ${sum} грн`

  return func({
    func: 'sendSms',
    sms: {
      phones: [getOnlyNumbers('+380677961717')],
      body: message,
      emulate: 0,
    },
  })
}

export default sendOllAppOrderSms
