import React from 'react'
import { Item, A } from 'react-html-email'

function EmailActionButton({ style, title, link }) {
  return (
    <Item align="center" style={style}>
      <A
        href={link}
        style={{
          textDecoration: 'none',
          display: 'inline-flex',
          background: '#5B0E8B',
          color: '#fff',
          padding: '11px 24px',
          borderRadius: '4px',
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: '700',
          cursor: 'pointer',
        }}
      >
        {title}
      </A>
    </Item>
  )
}

export default EmailActionButton
