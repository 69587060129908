import React, { useRef, useContext, useEffect } from 'react'
import { usePopUp } from '../../../hooks/usePopUp'
import Avatar from '../Avatar/Avatar'
import './ProfileButton.sass'
import ProfilePopUp from './ProfilePopUp/ProfilePopUp'
import { AuthContext } from '../../../App'

function ProfileButton() {
  const aRef = useRef()
  const pRef = useRef()
  const { user, showAuth } = useContext(AuthContext)
  const [showPopUp, setShowPopUp] = usePopUp(aRef, pRef)

  useEffect(() => {
    if (showAuth) setShowPopUp(false)
  }, [showAuth])

  return (
    <div className="ProfileButton">
      <Avatar ref={aRef} asset={user ? user.avatar : undefined} />
      {showPopUp && (
        <ProfilePopUp ref={pRef} close={() => setShowPopUp(false)} />
      )}
    </div>
  )
}

export default ProfileButton
