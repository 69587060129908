import { functions } from '../../../../config/firebase'

const sendConfirmationSms = ({ to, confirmationCode }) => {
  const func = functions.httpsCallable('serviceSmsOnCall')
  const message = `Ваш код для підтвердження: ${confirmationCode}`

  return func({
    func: 'sendSms',
    sms: {
      phones: to,
      body: message,
      emulate: 0,
    },
  })
}

export default sendConfirmationSms
