import React, { useState, useEffect } from 'react'
import './GenderOptions.sass'
import Icon from '../../../../ui/Icon/Icon'
import { cloneDeep, find, isEmpty } from 'lodash'

const options = [
  {
    value: null,
    icon: null,
    title: 'Не обрано',
  },
  {
    value: 'male',
    icon: 'male',
    title: 'Чоловік',
  },
  {
    value: 'female',
    icon: 'female',
    title: 'Жінка',
  },
]

function GenderOptions({ value = null, onGenderChanged }) {
  let val = value ? value : null

  const [opts, setOpts] = useState(
    options.map((o) => {
      o.isActive = o.value === val
      return o
    })
  )

  const updateOptions = (newActiveOpt) => {
    const cloneOptions = cloneDeep(options)
    setOpts(
      cloneOptions.map((o) => {
        o.isActive = o.value === newActiveOpt
        return o
      })
    )
  }

  useEffect(() => {
    if (!isEmpty(opts)) onGenderChanged(find(opts, ['isActive', true]).value)
  }, [opts])

  return (
    <div className="GenderOptions">
      <p className="Body-Bold-2">Стать</p>
      <div className="GenderOptions-Container">
        {opts.map((o) => (
          <Option {...o} onClick={() => updateOptions(o.value)} />
        ))}
      </div>
    </div>
  )
}

function Option({ title, isActive, icon, onClick }) {
  return (
    <span
      className={['Option', ...(isActive ? ['Option_theme_active'] : [])].join(
        ' '
      )}
      onClick={onClick}
    >
      {icon && <Icon name={icon} weight="solid" />}
      {title}
    </span>
  )
}

export default GenderOptions
