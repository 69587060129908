import React from 'react'
import './404.sass'
import illustration from './404.svg'
import Button from '../../ui/Button/Button'

function Page404() {
  return (
    <div className="Page404">
      <div className="Page404-Container">
        <img src={illustration} alt="Page 404" />
        <h2>Упс... такої сторінки не існує</h2>
        <p>Схоже, ви заблукали. Поверніться на головну сторінку.</p>
        <Button title="На головну" type="navlink" path="/" />
      </div>
    </div>
  )
}

export default Page404
