import { differenceInCalendarDays } from 'date-fns'
import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../App'
import Button from '../../../ui/Button/Button'
import DatePicker from '../../../ui/DatePicker/DatePicker'
import PopUp from '../../../ui/PopUp/PopUp'
import { createForm } from '../../../utils/forms/createForm'
import FormRender from '../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import './DatePickerPopUp.sass'

function DatePickerPopUp({
  title,
  onClose = null,
  onSameDayClicked = () => null,
}) {
  const { dates, setDates } = useContext(AuthContext)
  const [selectedDate, setSelectedDate] = useState(dates)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        sameDay: {
          field: {
            fieldId: 'sameDay',
            fieldType: 'checkbox',
            label: 'Заберу після 15:00 (-1 день оренди)',
          },
          render: getFieldRenderObject(),
        },
      },
      formData: {
        sameDay: localStorage.getItem('sameDay'),
      },
    })
  )

  useEffect(() => {
    if (form.sameDay.value) {
      localStorage.setItem('sameDay', true)
      onSameDayClicked(true)
    } else {
      localStorage.removeItem('sameDay')
      onSameDayClicked(false)
    }
  }, [form.sameDay.value])

  const onDateChange = ([start, end]) => {
    setSelectedDate({ start, end })
  }

  const onSave = () => {
    if (selectedDate.start && selectedDate.end) {
      localStorage.setItem('date', JSON.stringify(selectedDate))
      setDates(selectedDate)
      onClose && onClose()
    }
  }

  return (
    <PopUp show title={title} className={`DatePickerPopUp`} close={onClose}>
      <div className="DatePickerPopUp-Container">
        <DatePicker
          selected={selectedDate.start}
          startDate={selectedDate.start}
          endDate={selectedDate.end}
          selectsRange
          onChange={onDateChange}
          monthsShown={2}
          minDate={new Date()}
        />
        <div className="Buttons">
          {differenceInCalendarDays(selectedDate.end, selectedDate.start) >
          0 ? (
            <FormRender
              sections={[{ fields: ['sameDay'] }]}
              form={form}
              setForm={setForm}
            />
          ) : (
            <span />
          )}
          <div className="Buttons-Container">
            <Button
              title="Зберегти"
              theme="primary"
              size={40}
              disabled={selectedDate.start && selectedDate.end ? false : true}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </PopUp>
  )
}

export default DatePickerPopUp
