import { differenceInDays } from 'date-fns'
import { find, findIndex, difference } from 'lodash'

function defineProductPointPrice({
  pointId,
  products,
  cartProducts,
  dates,
  sameDay,
}) {
  const productsData = products.filter((p) =>
    find(cartProducts, ['productId', p.productId])
  )

  let days = differenceInDays(dates.end, dates.start)
  if (!sameDay) days += 1

  let prices = {}

  cartProducts.forEach((p) => {
    let price = 0
    const thisProductData = find(productsData, ['productId', p.productId])
    const thisPointPriceData = thisProductData.price[pointId]
    // const choosedOptions = ['main']
    const choosedOptions = []
    p.options.forEach((oI) => {
      choosedOptions.push(
        `option${findIndex(thisProductData.options, ['id', oI])}`
      )
    })

    //define price range
    const thisPriceRange = find(thisPointPriceData.priceRanges, (pr) => {
      const dateRange = pr.days.split('-')
      if (dateRange[0] && !dateRange[1]) {
        return days >= dateRange[0] && days <= dateRange[0]
      }

      if (dateRange[0] && dateRange[1]) {
        return days >= dateRange[0] && days <= dateRange[1]
      }

      return false
    })

    //if priceRange defined
    if (thisPriceRange) {
      //define if this is kit
      if (
        thisPointPriceData.productKitIs.every((elem) =>
          choosedOptions.includes(elem)
        )
      ) {
        price += days * p.quantity * thisPriceRange.kitPrice

        //add price for another options
        difference(choosedOptions, thisPointPriceData.productKitIs).forEach(
          (op) => {
            price += days * p.quantity * thisPriceRange[`${op}Price`]
          }
        )
      } else {
        choosedOptions.forEach((op) => {
          if (op === 'main') {
            price += days * p.quantity * thisPriceRange.productPrice
          } else {
            price += days * p.quantity * thisPriceRange[`${op}Price`]
          }
        })
      }
    }
    if (prices.hasOwnProperty(p.productId)) {
      prices[p.productId] += price
    } else {
      prices[p.productId] = price
    }
  })

  return prices
}

export default defineProductPointPrice
