import { functions } from '../../../../config/firebase'

const sendPasswordRecoverySms = ({ to, recoveryCode }) => {
  const func = functions.httpsCallable('serviceSmsOnCall')
  const message = `Ми отримали запит на відновлення вашого пароля. Скористайтесь посиланням нижче або скопіюйте та вставте його у свій браузер, щоб встановити новий пароль: https://ollapp.com.ua/password-recovery?recoveryCode=${recoveryCode}`

  return func({
    func: 'sendSms',
    sms: {
      phones: [to],
      body: message,
      emulate: 0,
    },
  })
}

export default sendPasswordRecoverySms
