import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'

export default class UserObject {
  constructor({ disabledFields = [] } = {}) {
    this.avatar = {
      field: {
        fieldId: 'avatar',
        value: Math.floor(Math.random() * 3) + 1,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: "Ім'я",
        placeholder: "Ім'я",
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.lastName = {
      field: {
        fieldId: 'lastName',
        fieldType: 'input',
        inputType: 'text',
        label: 'Прізвище',
        placeholder: 'Прізвище',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'input',
        inputType: 'phone',
        mask: 'phone',
        label: 'Телефон',
        placeholder: '+38(0XX) XXX-XX-XX',
        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: !disabledFields.includes('phone'),
      }),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'Електронна пошта',
        placeholder: 'sample@gmail.com',

        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: !disabledFields.includes('email'),
      }),
    }
    this.password = {
      field: {
        fieldId: 'password',
        fieldType: 'input',
        inputType: 'password',
        label: 'Пароль',
        placeholder: 'Створіть ваш пароль',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phoneVerified = {
      field: {
        fieldId: 'phoneVerified',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.phoneVerificationCode = {
      field: {
        fieldId: 'phoneVerificationCode',
        value: null,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.signUpType = {
      field: {
        fieldId: 'signUpType',
        value: 'manual',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: 1.0,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.cards = {
      field: {
        fieldId: 'cards',
        value: [],
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.height = {
      field: {
        fieldId: 'height',
        fieldType: 'input',
        inputType: 'number',
        label: 'Ріст, см',
        placeholder: '80-220 см',
      },
      render: getFieldRenderObject(),
    }
    this.weight = {
      field: {
        fieldId: 'weight',
        fieldType: 'input',
        inputType: 'number',
        label: 'Вага, кг',
        placeholder: 'до 140 кг',
      },
      render: getFieldRenderObject(),
    }
    this.footSize = {
      field: {
        fieldId: 'footSize',
        fieldType: 'input',
        inputType: 'text',
        label: 'Розмір ноги',
        placeholder: 'Не обрано',
      },
      render: getFieldRenderObject(),
    }
    this.gender = {
      field: {
        fieldId: 'gender',
        value: null,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.bDate = {
      field: {
        fieldId: 'bDate',
        fieldType: 'input',
        inputType: 'text',
        mask: 'date',
        label: 'День народження',
        placeholder: 'Не вказано',
      },
      render: getFieldRenderObject(),
    }
    this.city = {
      field: {
        fieldId: 'city',
        fieldType: 'input',
        inputType: 'text',
        label: 'Місто',
        placeholder: 'Не вказано',
      },
      render: getFieldRenderObject(),
    }
    this.maritalStatus = {
      field: {
        fieldId: 'maritalStatus',
        fieldType: 'select',
        label: 'Сімейний стан',
        placeholder: 'Не вказано',
        getOptions: [
          {
            label: 'одружений/заміжня',
            value: 'married',
          },
          { label: 'неодружений/незаміжня', value: 'single' },
        ],
      },
      render: getFieldRenderObject(),
    }
  }
}

// formPattern: {
//   name: {
//     field: {
//       fieldId: 'name',
//       fieldType: 'input',
//       inputType: 'text',
//       label: "Ім'я",
//       placeholder: "Ім'я",
//       required: true,
//     },
//     render: getFieldRenderObject(),
//   },
//   email: {
//     field: {
//       fieldId: 'email',
//       fieldType: 'input',
//       inputType: 'email',
//       validationPattern: 'email',
//       label: 'Електронна пошта',
//       placeholder: 'sample@gmail.com',
//       required: true,
//     },
//     render: getFieldRenderObject(),
//   },
//   lastName: {
//     field: {
//       fieldId: 'lastName',
//       fieldType: 'input',
//       inputType: 'text',
//       label: 'Прізвище',
//       placeholder: 'Прізвище',
//       required: true,
//     },
//     render: getFieldRenderObject(),
//   },
//   phone: {
//     field: {
//       fieldId: 'phone',
//       fieldType: 'input',
//       inputType: 'phone',
//       label: 'Телефон',
//       placeholder: '+38(0XX) - XXX - XXXX',
//       required: true,
//     },
//     render: getFieldRenderObject(),
//   },
//   gender: {
//     field: {
//       fieldId: 'gender',
//     },
//     render: getFieldRenderObject({ isSystem: true }),
//   },
//   height: {
//     field: {
//       fieldId: 'height',
//       fieldType: 'input',
//       inputType: 'number',
//       label: 'Ріст, см',
//       placeholder: '80-220 см',
//     },
//     render: getFieldRenderObject(),
//   },
//   weight: {
//     field: {
//       fieldId: 'weight',
//       fieldType: 'input',
//       inputType: 'number',
//       label: 'Вага, кг',
//       placeholder: 'до 140 кг',
//     },
//     render: getFieldRenderObject(),
//   },
//   footSize: {
//     field: {
//       fieldId: 'footSize',
//       fieldType: 'input',
//       inputType: 'text',
//       label: 'Розмір ноги',
//       placeholder: 'Не обрано',
//     },
//     render: getFieldRenderObject(),
//   },
//   bDate: {
//     field: {
//       fieldId: 'bDate',
//       fieldType: 'input',
//       inputType: 'number',
//       mask: 'date',
//       label: 'День народження',
//       placeholder: 'Не вказано',
//     },
//     render: getFieldRenderObject(),
//   },
//   city: {
//     field: {
//       fieldId: 'city',
//       fieldType: 'input',
//       inputType: 'text',
//       label: 'Місто',
//       placeholder: 'Не вказано',
//     },
//     render: getFieldRenderObject(),
//   },
//   maritalStatus: {
//     field: {
//       fieldId: 'maritalStatus',
//       fieldType: 'input',
//       inputType: 'text',
//       label: 'Сімейний стан',
//       placeholder: 'Не вказано',
//     },
//     render: getFieldRenderObject(),
//   },
// },
