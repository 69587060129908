import React from 'react'
import './Stepper.sass'
import Icon from '../../ui/Icon/Icon'
import { withRouter } from 'react-router-dom'

function Stepper({ steps, width, ...router }) {
  const oneStepPercentage = 100 / (steps.length - 1)
  const progress =
    oneStepPercentage * steps.filter((s) => s.status === 'done').length

  return (
    <div className="Stepper" style={{ width }}>
      <div
        className="StepBar"
        style={{
          background:
            progress === 0
              ? `#CAD0D5`
              : `linear-gradient(to right, #6FD44B ${progress}%, #CAD0D5 ${
                  100 - progress
                }% 100%)`,
        }}
      />
      {steps.map((s, i) => (
        <Step {...s} lastStep={i === steps.length - 1} router={router} />
      ))}
    </div>
  )
}

function Step({ status, title, path, lastStep, router }) {
  return (
    <div
      className={[
        'Step',
        `Step_status_${status}`,
        lastStep ? 'LastStep' : '',
      ].join(' ')}
      onClick={path ? () => router.history.push(path) : () => null}
    >
      <div className="Marker">{status === 'done' && <Icon name="check" />}</div>
      <div className="Title Caption-Medium">{title}</div>
    </div>
  )
}

export default withRouter(Stepper)
