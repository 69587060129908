import React from 'react'
import { Item, Image, Box, A } from 'react-html-email'

function EmailBody({ children }) {
  return (
    <div
      style={{
        width: '100%',
        background: '#ffffff',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          border: '1px solid #E2E6EB',
          borderRadius: '8px',
          marginBottom: '64px',
        }}
      >
        <Box
          align="center"
          style={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Item align="center" style={{ paddingTop: '16px' }}>
            <A
              href="https://ollapp.com.ua"
              target="_blank"
              textDecoration="none"
            >
              <Image
                src="https://i.ibb.co/Jkvbvkr/2.png"
                width={100}
                height={38}
                alt="OllApp"
                style={{ display: 'block' }}
              />
            </A>
          </Item>
          <Item
            style={{
              padding: '40px',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
          >
            <Box
              style={{
                width: '100%',
                fontSize: '16px',
              }}
            >
              {children}
              {/* <EmailCopyrights /> */}
              {/* <EmailSignature /> */}
            </Box>
          </Item>
          {/* <EmailCopyrights /> */}
        </Box>
      </div>
    </div>
  )
}

export default EmailBody
