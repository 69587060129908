import React, { useEffect, createContext, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import { db, firebase } from './config/firebase'
import CatalogIndex from './pages/Catalog/CatalogIndex'
import Checkout from './pages/Checkout/Checkout'
import PaymentStatusPage from './pages/PaymentStatusPage/PaymentStatusPage'
import Profile from './components/Profile/Profile'
import Auth from './components/Auth/Auth'
import PrivateRoute from './hocs/routs/PrivateRoute'
import PasswordResetEmail from './components/Emails/PasswordResetEmail'
import PasswordRecoveryPage from './pages/PasswordRecoveryPage/PasswordRecoveryPage'
import getDoc from './utils/db/getDoc'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Contacts from './pages/Contacts/Contacts'
import Faq from './pages/Faq/Faq'
import Page404 from './pages/404/404'
import PaymentProcess from './pages/PaymentProcess/PaymentProcess'
import addDoc from './utils/db/addDoc'
import setDoc from './utils/db/setDoc'
import LocationNotificationPopUp from './ui/LocationNotificationPopUp/LocationNotificationPopUp'
import { isEmpty } from 'lodash'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'

export const AuthContext = createContext(null)

const initDates = () => {
  if (localStorage.getItem('date')) {
    let dObject = JSON.parse(localStorage.getItem('date'))
    dObject.start = new Date(dObject.start)
    dObject.end = new Date(dObject.end)
    return dObject
  }
  return { start: null, end: null }
}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [cart, setCart] = useState({ id: null, products: [] })
  const [showAuth, setShowAuth] = useState(false)
  const [category, setCategory] = useState({ id: null, title: null })
  const [location, setLocation] = useState({
    id: null,
    title: null,
    lat: null,
    lng: null,
  })
  const [dates, setDates] = useState(initDates())
  const [showLocationNotice, setShowLocationNotice] = useState(false)

  useEffect(() => {
    let dbUserListener = () => null
    let dbCartListener = () => null
    const userStateListener = firebase.auth().onAuthStateChanged((result) => {
      if (result && result.uid) {
        dbCartListener()
        dbUserListener = db
          .collection('users')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const user = {
              uid: snap.id,
              ...snap.data(),
            }
            setUser(user)
          })

        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (gCartData) => {
              if (!isEmpty(gCartData.products)) {
                setDoc({
                  path: 'carts',
                  docId: result.uid,
                  data: { products: gCartData.products },
                }).then(() =>
                  db
                    .collection('carts')
                    .doc(localStorage.getItem('cart'))
                    .delete()
                    .then(() => localStorage.removeItem('cart'))
                )
              }
            }
          )
        }

        dbCartListener = db
          .collection('carts')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const uCart = {
              id: snap.id || null,
              products: [],
              ...snap.data(),
            }
            setCart(uCart)
          })
      } else {
        setUser(null)

        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (result) => {
              if (result) {
                dbCartListener = db
                  .collection('carts')
                  .doc(localStorage.getItem('cart'))
                  .onSnapshot((snap) => {
                    const uCart = {
                      id: snap.id || null,
                      products: [],
                      ...snap.data(),
                    }
                    setCart(uCart)
                  })
              } else {
                addDoc({
                  path: 'carts',
                  data: { products: [], cartType: 'guest' },
                }).then((docId) => {
                  localStorage.setItem('cart', docId)
                  dbCartListener = db
                    .collection('carts')
                    .doc(docId)
                    .onSnapshot((snap) => {
                      const uCart = {
                        id: docId,
                        products: [],
                        ...snap.data(),
                      }
                      setCart(uCart)
                    })
                })
              }
            }
          )
        } else {
          addDoc({
            path: 'carts',
            data: { products: [], cartType: 'guest' },
          }).then((docId) => {
            localStorage.setItem('cart', docId)
            dbCartListener = db
              .collection('carts')
              .doc(docId)
              .onSnapshot((snap) => {
                const uCart = {
                  id: docId,
                  products: [],
                  ...snap.data(),
                }
                setCart(uCart)
              })
          })
        }
        dbUserListener()
      }
    })
    return () => {
      userStateListener()
      dbUserListener()
      setUser(null)
      setCart({ id: null, products: [] })
      dbCartListener()
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('category')) {
      getDoc({
        path: 'config/products/categories',
        docId: localStorage.getItem('category'),
        docIdName: 'id',
      }).then((c) => setCategory(c))
    }

    if (localStorage.getItem('location')) {
      getDoc({
        path: 'cities',
        docId: localStorage.getItem('location'),
        docIdName: 'id',
      }).then((l) => setLocation(l))
    }
  }, [])

  useEffect(() => {
    const product = cart.products[0]
    if (
      (product && location.id && product.locationId !== location.id) ||
      (product && !location.id)
    ) {
      setShowLocationNotice(true)
    } else {
      setShowLocationNotice(false)
    }
  }, [location, cart])

  return (
    <AuthContext.Provider
      value={{
        user,
        showAuth,
        setShowAuth,
        category,
        setCategory,
        location,
        setLocation,
        dates,
        setDates,
        cart,
      }}
    >
      {showLocationNotice && <LocationNotificationPopUp />}
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Auth />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path={['/catalog/:subCategoryId', '/catalog']}
          component={CatalogIndex}
        />
        <Route path="/checkout" component={Checkout} />
        <Route
          path="/payment/process"
          render={() => <PaymentProcess />}
        ></Route>
        <Route
          path="/payment/success"
          render={() => <PaymentStatusPage status="success" />}
        ></Route>
        <Route
          path="/payment/failed"
          render={() => <PaymentStatusPage status="failed" />}
        ></Route>
        <PrivateRoute path="/profile" component={Profile} redirectTo="/" />
        <Route path="/email" component={PasswordResetEmail} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/faq" component={Faq} />
        <Route path="/:notFound" component={Page404} />
      </Switch>
    </AuthProvider>
  )
}

export default App
