import React from 'react'
import SitePage from '../SitePage/SitePage'

function TermsAndConditions() {
  return (
    <SitePage withFormData>
      <div className="Site-Content PageText-Container">
        <h2>Правилами та умовами використання сервісу</h2>
        <p>
          Реєструючись на сайті та використовуючи його, ви погоджуєтеся з цими
          Загальними умовами та договором публічної оферти, які відповідно до
          Закону України «Про електронну комерцію», становлять договір між вами
          та «ОЛЛАПП Україна» в електронній формі.
        </p>
        <p>
          Якщо ви не погоджуєтеся з цими умовами, не використовуйте, будь ласка,
          цей сайт. «ОЛЛАПП Україна»надає користувачам (надалі – «Користувач»
          або «Користувачі») можливість користуватися веб-сайтом ollapp.com.ua,
          надалі спільно іменуються «Платформа») тільки після прийняття ними цих
          Загальних умов. Ці правила та умови, які можуть бути змінені час від
          часу, стосуються всіх наших послуг прямо чи опосередковано та
          надаються в режимі онлайн, електронною поштою або за телефоном.
          Відвідуючи, переглядаючи або використовуючи наш веб-сайт, ви визнаєте,
          що прочитали, зрозуміли й погодилися з нижченаведеними правилами та
          умовами та договором публічної оферти.
        </p>
        <p>
          Ці сторінки, контент і інфраструктура цих сторінок, а також сервіс
          онлайн-бронювань (включаючи послугу обробки платежів), що надається
          нами на цих сторінках і через сайт, належать і управляються інадаються
          виключно для вашого особистого, некомерційного використання з
          урахуванням наведенихнижче умов. Відносини між нами і Наймодавцями
          регулюються окремими умовами, щ о описують комерційну діяльність, яку
          ми ведемо з кожним Наймодавцем. Кожен Наймодаваць діє професійно у
          відношенні до «ОЛЛАПП Україна», коли надає доступ до свого продукту і
          / або сервісу на / через «ОЛЛАПП Україна» для кінцевих користувачів.
          На додаток до правил і важливої інформації, зазначеної на сайті,
          Наймодавці мають право застосувати і / або встановити власні додаткові
          умови і правила, що відносяться до використання послуг (включаючи
          положення прообмеження відповідальності).
        </p>
        <h3>
          Договір публічної оферти з користувачами сервісу та платформи OLLAPP
        </h3>
        <p>
          Ця публічна оферта, відповідно до статей 633, 641, глави 63 Цивільного
          кодексу України є пропозицією про укладення Договору, істотні умови
          якого викладено нижче, згідно якої Товариство з обмеженою
          відповідальністю «ОЛЛАПП Україна» (код ЄДРПОУ 43895525),звертається до
          осіб, які мають намір користуватись Сервісом OLLAPP.
        </p>
        <p>
          Користувач, шляхом реєстрації в ollapp.com.ua, приймає цю публічну
          Оферту, беззастережно та безумовно погоджується з умовами
          передбаченими цією Офертою, і зобов’язаннями, покладеними на
          Користувача в рамках цієї Оферти і зобов’язується дотримуватися їх.
          Про прийняття Користувачем Оферти також може свідчити оплата вартості
          Спорядження і вартості додаткових послуг у спосіб, зазначений на сайті
          OLLAPP.
        </p>
        <p>
          Актуальна вартість Спорядження визначається при кожному Акцепті Оферти
          за кожне замовлення окремо на підставі встановлених цін, ознайомитися
          з якими Користувач безперешкодно має право на сайті ollapp.com.ua
        </p>
        <h4>1. Визначення термінів та загальні положення</h4>
        <p>
          1.1. Договір публічної оферти - публічний договір, зразок якого
          розміщений на веб-сайті ollapp.com.ua.
          <br />
          1.2. Платформа/Сервіс OLLAPP – це веб-сторінка в мережі Інтернет за
          адресою ollapp.com.ua, яка є офіційним джерелом інформування
          Користувачів та Наймодавців про послуги, які їм надаються.
          <br />
          1.3. Акцепт – повне, безумовне і беззастережне прийняття Користувачем
          в тому вигляді, в якому вони викладені в тексті цієї Оферти.
          <br />
          1.4. Користувач – фізична особа, яка має повну цивільну правоздатність
          та дієздатність, яка є користувачем Платформи ollapp.com.ua або
          оформила замовлення через ollapp.com.ua та акцептувала даний Договір.
          <br />
          1.5. OLLAPP — «ОЛЛАПП Україна», яке має право від імені та за рахунок
          Наймодавців розміщувати на Платформі OLLAPP пропозиції Наймодавців та
          укладати Договір найму спорядження Наймодавця.
          <br />
          1.6. Спорядження — сукупність предметів, пристроїв, необхідних для
          чого-небудь, яке підлягає реалізації Наймодавцем на умовах цієї угоди
          (Оферти).
          <br />
          1.7. Замовлення — відповідним чином оформлене і розміщене на
          ollapp.com.ua замовлення Користувача в прокат Спорядження у
          Наймодавця.
          <br />
          1.8. Наймодавець – власник магазинів та точок з оренди спорядження,
          інтереси якого забезпечує Платформа/Сервіс OLLAPP.
        </p>
        <h4>2. Предмет договору</h4>
        <p>
          2.1. За цим договором OLLAPP надає доступ до Платформи/Сервісу OLLAPP
          та якісний та повний сервіс Споживачам.
          <br />
          2.2. Сервіс OLLAPP дозволяє Наймодавцям розміщувати інформаціюпро своє
          Спорядження. При цьому OLLAPP від імені Наймодавців та на підставі
          укладених з ними агентських договорів пропонує Користувачам можливість
          укласти Договір найму Спорядження, розміщеного у Сервісі OLLAPP.
          <br />
          2.3. OLLAPP не є Наймодавцем Спорядження, в тому числі в розумінні
          Закону України «Про захист прав споживачів». Відтак усі претензії щодо
          найму Спорядження, якості, кількості та інших умов найму, можуть
          пред’являтися виключно до Наймодавця такого Спорядження.
          <br />
          2.4. OLLAPP не є особою, яка здійснює прийняття та/або передачу,
          та/або розгляд претензій від Користувачів, пов’язаних із Спорядженням
          та організацією доставки Спорядження у разі наявності такої послуги.
          <br />
          2.7. OLLAPP не здійснює повернення Користувачам грошових коштів за
          Спорядження після її оплати Користувачами, крім випадків, якщо
          Користувач скасував замовлення на Платформі/Сервісі OLLAPP не менше,
          ніж за 72 години до початку дати оренди спорядження. Також в разі
          невиконання Наймодавцев замовлення Користувача, грошові кошти
          повертаються OLLAPP.
          <br />
          2.8. Претензії, пов’язані із Спорядженням та з питаннями повернення
          грошових коштів (у випадку готівкового розрахунку) за Спорядження,
          розглядаються Наймодавцем відповідного Спорядження. У разі повернення
          безготівкового розрахунку дані претензії розглядає OLLAPP.
        </p>
        <h4>3. Реєстрація на Платформі</h4>
        <p>
          3.1. Для можливості оформлення замовлень та використання сервісу
          OLLAPP Користувачеві необхідно зареєструватися на Платформі
          ollapp.com.ua (створити обліковий запис). При заповненні форми
          реєстрації на Платформі ollapp.com.ua Користувач погоджується з
          умовами даної Оферти, натискаючи кнопку «Зареєструватися».
          <br />
          3.2. Шляхом заповнення форми реєстрації Користувач погоджується на
          надання точної і правдивої інформації про себе. У разі надання
          Користувачем недостовірної інформації про себе, OLLAPP матиме право
          відмовити Користувачеві в оформленні та прийнятті його замовлення
          та/або відмовити такій особі у доступі до Платформи та/ або реєстрації
          в ньому.
          <br />
          3.3. OLLAPP надає Користувачеві можливість створення єдиного
          персонального облікового запису (профілю). У разі створення
          Користувачем більше одного облікового запису OLLAPP залишає за собою
          право видалити створені Користувачем інші облікові записи та/ або
          відмовити Користувачеві у доступі до Платформи.
          <br />
          3.4. Дозволяється реєстрація на Платформі фізичних осіб, які мають
          повну цивільну правоздатність та дієздатність. Користувач підтверджує,
          що він має повну цивільну правоздатність та дієздатність.
          <br />
          3.5. Користувач несе повну відповідальність за безпеку свого логіна і
          пароля, а також за всі дії, що будуть вчинені під його обліковим
          записом на Платформі.
          <br />
          3.6. У разі, якщо OLLAPP запідозрить Користувача у вчиненні
          протиправних дій, в тому числі у: шахрайстві з банківськими картами,
          розповсюдженні спаму, шкідливих програм, інших діянь, що порушують
          умови цієї Оферти, OLLAPP має право звернутися у відповідні державні
          органи з заявою та/або заблокувати і/або видалити обліковий запис
          Користувача.
          <br />
          3.7. Шляхом реєстрації на Платформі Користувач надає OLLAPP повне
          право (дозвіл) на обробку його персональних даних з метою укладення
          цивільно-правових угод, надання та/або передачі персональних даних
          третім особам в порядку і на підставах, визначених чинним
          законодавством України та внутрішніми документами OLLAPP, без
          необхідності повідомлення про такі дії. У разі зміни визначеної мети
          обробки персональних даних Користувач не заперечує проти обробки його
          персональних даних, якщо така необхідність буде визначена чинним
          законодавством України та/або внутрішніми документами OLLAPP, а також
          повідомляє, що він ознайомлений з Законом України «Про захист
          персональних даних». Крім того, Користувач підтверджує факт
          повідомлення про включення його персональних даних до відповідної бази
          персональних даних, мету збору даних та осіб, яким передаються його
          персональні дані.
          <br />
          3.8. Користувач шляхом реєстрації на Платформі дає свою згоду на
          отримання регулярних розсилок з актуальною інформацією щодо
          Спорядження Наймодавців, акцій і бонусних програм на свою електронну
          пошту та/або у вигляді sms-повідомлень.
          <br />
          3.9. Користувач підтверджує, що отримання Користувачем інформації на
          його електронну пошту і/або у вигляді будь-яких sms повідомлень, не є
          спамом.
          <br />
          3.10. Користувач має право у будь-який момент відмовитися від послуг
          регулярних розсилок, звернувшись у службу підтримки OLLAPP за номером
          телефону або по електронній пошті OLLAPP, зазначених на Платформі.
          <br />
          3.11. Підставою для припинення доступу Користувача до послуг
          регулярних розсилок є заява Користувача, складена за формою,
          встановленою OLLAPP, яку Користувач заповнює та надсилає на електронну
          пошту OLLAPP. Термін розгляду заяви Користувача не повинен
          перевищувати 5 (п’яти) робочих днів з моменту отримання OLLAPP заяви
          від Користувача.
        </p>
        <h4>4. Права і обов’язки OLLAPP </h4>
        <div>
          <p className="Body-Regular-2">4.1. Обов’язки OLLAPP:</p>
          <p>
            4.1.1. Приймати, обробляти замовлення, повідомляти Користувачеві
            інформацію щодо змін в статусі замовлення, часу і вартості.
            <br />
            4.1.2. Не розміщувати на Платформі будь-яку інформацію, що порушує
            права третіх осіб на результати інтелектуальної діяльності.
            <br />
            4.1.3. Приймати грошові кошти в рахунок оплати Спорядження;
            <br />
            4.1.4. Інші обов’язки OLLAPP, передбачені цією Офертою.
          </p>
        </div>
        <h4>5. Права та обов’язки Користувача</h4>
        <div>
          <p className="Body-Regular-2">5.1. Обов’язки Користувача: </p>
          <p>
            5.1.1. Вказувати достовірну, повну і точну інформацію про себе при
            реєстрації на Платформі. Якщо інформація в реєстрації виявляється
            недостовірною, OLLAPP не несе відповідальності за неможливість
            встановлення зв’язку з Користувачем і надання сервісу за даним
            Договором.
            <br />
            5.1.2. Виконувати вимоги чинного законодавства та вимоги Договору,
            пов’язані з наданням сервісу.
            <br />
            5.1.3. Не порушувати права інтелектуальної власності OLLAPP. Надання
            сервісу не дає Користувачеві прав інтелектуальної власності на
            використання даного Сервісу у комерційних цілях.
          </p>
        </div>
        <div>
          <p className="Body-Regular-2">5.1 Права Користувача:</p>
          <p>
            5.2.1. Отримати сервіс належної якості відповідно до Оферти.
            <br />
            5.2.2. Отримати від OLLAPP всю необхідну інформацію для отримання
            Спорядження в найм. Звертатися до OLLAPP з питань, пов’язаних з
            наданням сервісу.
            <br />
            5.2.3. Залишати відгуки про Платфору/Сервіс OLLAPP.
          </p>
        </div>
        <h4>6. Відповідальність Сторін.</h4>
        <p>
          6.1. За невиконання або неналежне виконання зобов’язань за цим
          договором сторони несуть відповідальність відповідно до чинного
          законодавства.
          <br />
          6.2. Сервіс OLLAPP і вся пов’язана інформація надається «як є», без
          будь-яких гарантій, явних чи неявних. OLLAPP не надає ніяких гарантій,
          в тому числі комерційної придатності, придатності для певних цілей і
          непорушення прав третіх осіб, а також гарантій, що випливають з
          ділових відносин або звичаїв ділового обороту. Крім того, OLLAPP
          відмовляється від відповідальності, пов’язаної з доступом Користувача
          до Сервісу і пов’язаної інформації , а також з їх використанням.
          Користувач погоджується з тим, що здійснює доступ до Сервісу і
          пов’язаних матеріалів і використовує їх на власний ризик.
          <br />
          6.3. У максимально дозволеною законодавством мірою OLLAPP не несе
          відповідальності за непрямі, побічні, фактичні, непрямі або прямі
          збитки, прямо або побічно упущену вигоду або неотримання доходів,
          втрату даних, експлуатаційних якостей або інших нематеріальних
          цінностей, пов’язані: а) з доступом Користувача до Сервісу і їх
          використанням або неможливістю такого доступу або використання; б) з
          матеріалами або поведінкою, в тому числі ганебним, образливим або
          незаконним, будь-якої третьої сторони. Ні в якому разі сума сукупної
          відповідальності за всіма претензіями щодо Сервісу не може
          перевищувати загальної суми, отриманої від Користувача за надання йому
          сервісу протягом останніх двох разів підряд.
          <br />
          6.4. Користувач погоджується, що відмова від гарантій і обмеження
          відповідальності, викладені в даних умовах, відображають розумний і
          справедливий розподіл ризиків, а також є необхідною умовою для надання
          Сервісу OLLAPP за доступну плату
        </p>
        <h4>7. Форс-мажор</h4>
        <p>
          7.1. Сторони звільняються від відповідальності за повне або часткове
          невиконання зобов’язань, якщо це невиконання викликане обставинами, що
          не залежать від волі Сторін, а саме: військовими діями, стихійним
          лихом, техногенними та іншими аваріями, страйками, карантинними
          локдаунами та будь-якого роду епідеміями, актами органів влади або
          управління тощо, що унеможливлює виконання умов цього Договору (далі
          Форс-мажор).
          <br />
          7.2. Форс-мажор застосовується, і Сторона, для якої він настав
          звільняється від відповідальності за порушення умов цього Договору,
          при наявності письмового підтвердження настання форс-мажорних
          обставин.
          <br />
          7.3. Сторона, для якої наступили вказані обставини, зобов’язується
          негайно повідомити іншу Сторону та надати відповідні підтверджувальні
          документи.
          <br />
          7.4. З моменту отримання такого повідомлення іншою стороною виконання
          умов даного Договору припиняється на весь період дії Форс-мажору.
          <br />
          7.5. У разі дії Форс-мажорних обставин більше 3 (трьох) місяців, кожна
          Сторона має право ініціювати припинення Договору.
        </p>
        <h4>8. Припинення Договору</h4>
        <p>
          Дія цього Договору припиняється:
          <br />
          <br />
          8.1. За згодою Сторін.
          <br />
          8.2. Якщо виконання Стороною Договору своїх зобов’язань неможливо в
          зв’язку з прийняттям нормативно-правових актів, які змінили умови,
          встановлені цим Договором, і будь-яка із Сторін не погоджується на
          внесення відповідних змін до Договору.
          <br />
          8.3. У випадках порушення Користувачем умов, передбачених Офертою.
          <br />
          8.4. В інших випадках, передбачених цим Договором та чинним
          законодавством.
        </p>
        <h4>9. Обробка персональних даних</h4>
        <p>
          9.1. Користувач дає згоду на використання і обробку своїх персональних
          даних в адміністративно-правових і комерційних цілях.
          <br />
          Згода надається щодо персональних даних на здійснення будь-яких дій,
          допустимих відповідно до законодавства і які необхідні для виконання
          цього Договору, включаючи: збір, систематизацію, зберігання, уточнення
          (оновлення, зміну), використання, передачу (надання, доступ),
          включаючи транскордонну передачу, знеособлення, блокування, знищення;
          з використанням і без використання засобів автоматизації як способів
          обробки персональних даних. При цьому Користувач дає згоду на передачу
          своїх персональних даних третім особам в мінімально необхідних обсягах
          з метою виконання вимог законодавства та даних договірних зобов’язань
          з дотриманням вимог чинного законодавства.
          <br />
          9.2. Обробка персональних даних Користувачів також регулюється
          Політикою конфіденційності, яка викладена на Платформі OLLAPP.
        </p>
        <h4>10. Термін дії оферти</h4>
        <p>
          10.1. Ця Оферта набуває чинності з моменту її розміщення на Платформі
          OLLAPP і діє до моменту її відкликання OLLAPP.
          <br />
          10.2. OLLAPP має право в будь-який час внести зміни в умови Оферти
          та/або відкликати Оферту в будь-який момент на свій розсуд. У разі
          внесення OLLAPP змін в Оферту, такі зміни вступають в силу з моменту
          розміщення зміненого тексту Оферти на Платформі OLLAPP, якщо інший
          термін вступу змін в силу не визначений безпосередньо в тексті
          зміненої Оферти. Про дані зміни OLLAPP не зобов’язаний повідомляти
          Користувачів.
        </p>
        <h4>11. Заключні положення</h4>
        <p>
          11.1. Сторони встановили, що будь-які спори і претензії будуть
          вирішуватися шляхом переговорів.
          <br />
          11.2. Сторони усвідомлюють, що Сервіс надається OLLAPP, який
          зареєстрований і діє відповідно до законодавства України.
          <br />
          11.3. Місцем укладання угоди і місцем надання Сервісу Сторони
          домовилися вважати місце знаходження OLLAPP, вказане в розділі 12
          цього Договору.
          <br />
          11.4. Акцептуючи дану Оферту Користувач погоджується, що всі
          суперечки, пов’язані з цим Договором, будуть розглядатися відповідно
          до законодавства України без урахування норм колізійного права.
          Користувач також погоджується, що всі такі суперечки знаходяться у
          виключній компетенції відповідних судів України.
          <br />
          11.5. Заголовки, що використовуються в статтях і пунктах цієї Оферти,
          використовуються тільки для посилань і зручності користування текстом.
          Дані заголовки не можуть розглядатися як визначальні, що обмежують або
          змінюють, або впливають на значення і зміст умов цієї Оферти чи її
          будь-якої частини.
          <br />
          11.6. Сторони цього Договору вирішили, що в разі визнання частини
          цього Договору недійсною (незаконною), сам Договір і без включення в
          нього недійсної (незаконної) частини вважається укладеним. В цьому
          випадку Сторона цього Договору не може посилатися на те, що без
          включення недійсної (незаконний) частини в Договір вона б його не
          уклала.
          <br />
          11.7. Все викладене, становить єдиний текст Договору, який замінює всі
          попередні переговори, листування, угоди, пропозиції і заяви, укладені
          або висловлені Сторонами по предмету Договору в усній або письмовій
          формі.
          <br />
          11.8. У всіх випадках, які не передбачені цією Офертою, Сторони
          керуються чинним законодавством України.
        </p>
        <h4>12. Реквізити OLLAPP</h4>
        <p className="Body-Bold-2">
          Товариство з обмеженою відповідальністю «ОЛЛАПП Україна»
        </p>
        <p>
          Юридична адреса: вулиця Чоловського, 5, місто Львів, Україна, 79018
          <br />
          Код за ЄДРПОУ: 43895525
        </p>
      </div>
    </SitePage>
  )
}

export default TermsAndConditions
