import React, { useContext } from 'react'
import SitePageHeader from '../../SitePage/SitePageHeader/SitePageHeader'
import TabsController from '../../../ui/TabsController/TabsController'
import Stepper from '../../../ui/Stepper/Stepper'
import { find, isEmpty, sum } from 'lodash'
import { AuthContext } from '../../../App'
import { withRouter } from 'react-router-dom'

function CatalogPageHeader({
  categories = [],
  assignmentFilter,
  setAssignmentFilter,
  ...router
}) {
  const { cart } = useContext(AuthContext)
  const currentCategory = find(categories, ['status', 'active']) || {}

  const tabs = [
    {
      id: 'male',
      title: 'Для чоловіків',
      counter: sum(
        cart.products
          .filter(
            (p) =>
              p.assignment === 'male' &&
              currentCategory.categoryId === p.categoryId
          )
          .map((p) => p.quantity)
      ),
    },
    {
      id: 'female',
      title: 'Для жінок',
      counter: sum(
        cart.products
          .filter(
            (p) =>
              p.assignment === 'female' &&
              currentCategory.categoryId === p.categoryId
          )
          .map((p) => p.quantity)
      ),
    },
    {
      id: 'kid',
      title: 'Для дітей',
      counter: sum(
        cart.products
          .filter(
            (p) =>
              p.assignment === 'kid' &&
              currentCategory.categoryId === p.categoryId
          )
          .map((p) => p.quantity)
      ),
    },
  ]

  const title = find(categories, ['status', 'active'])
    ? find(categories, ['status', 'active']).title
    : !isEmpty(categories)
    ? 'Підсумок'
    : null

  return (
    <SitePageHeader
      title={title}
      underTitle={
        router.match.params.subCategoryId !== 'totals' && (
          <TabsController
            tabs={tabs.map((t) => {
              t.isActive = t.id === assignmentFilter
              return t
            })}
            onClick={setAssignmentFilter}
          />
        )
      }
      rightSide={
        !isEmpty(categories) ? (
          <Stepper
            steps={[
              ...categories,
              {
                status:
                  router.match.params.subCategoryId === 'totals'
                    ? 'active'
                    : 'notActive',
                title: 'Підсумок',
                path: '/catalog/totals',
              },
            ]}
            width="450px"
          />
        ) : null
      }
    />
  )
}

export default withRouter(CatalogPageHeader)
