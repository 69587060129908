import React, { useContext, useEffect, useState } from 'react'
import PopUp from '../../ui/PopUp/PopUp'
import SignIn from './SignIn/SignIn'
import SignUp from './SignUp/SignUp'
import './Auth.sass'
import PhoneConfirmation, {
  PhoneCodeConfirmation,
} from './PhoneConfirmation/PhoneConfirmation'
import { AuthContext } from '../../App'
import PasswordRecovery from './PasswordRecovery/PasswordRecovery'
import PasswordRecoveryMessage from './PasswordRecovery/PasswordRecoveryMessage/PasswordRecoveryMessage'
import PasswordResetForm from './PasswordRecovery/PasswordResetForm/PasswordResetForm'
import { withRouter } from 'react-router'
import { removeUrlQueryParams } from '../../utils/queries/index'

function Auth({ ...router }) {
  const { user, showAuth, setShowAuth } = useContext(AuthContext)
  let settings = { title: '', className: '', component: null }
  const [credPasswordRestore, setCredPasswordRestore] = useState(null)

  useEffect(() => {
    const search = router.location.search
    const params = new URLSearchParams(search)
    if (params.get('passwordReset')) {
      setShowAuth('passwordReset')
    }
  }, [router.location.search])

  switch (showAuth) {
    case 'signIn':
      settings = {
        title: 'Вхід',
        className: 'SignIn',
        component: (
          <SignIn
            setShowAuth={setShowAuth}
            onSignUpClicked={() => setShowAuth('signUp')}
            onForgotPassClicked={() => setShowAuth('passwordRecovery')}
          />
        ),
      }
      break
    case 'signUp':
      settings = {
        title: 'Реєстрація',
        className: 'SignUp',
        component: <SignUp setShowAuth={setShowAuth} />,
      }
      break
    case 'phoneConfirmation':
      settings = {
        title: 'Підтвердження телефону',
        className: 'PhoneConfirmation',
        component: <PhoneConfirmation />,
      }
      break
    case 'phoneCodeConfirmation':
      settings = {
        title: 'Введіть код',
        className: 'PhoneCodeConfirmation',
        component: (
          <PhoneCodeConfirmation onCodeConfirmed={() => setShowAuth(false)} />
        ),
      }
      break
    case 'passwordRecovery':
      settings = {
        title: 'Відновлення паролю',
        className: 'PasswordRecovery',
        component: (
          <PasswordRecovery
            onMessageSend={(to) => {
              setShowAuth('passwordRecoveryMessage')
              setCredPasswordRestore(to)
            }}
          />
        ),
      }
      break
    case 'passwordRecoveryMessage':
      settings = {
        className: 'PasswordRecovery-Message',
        component: <PasswordRecoveryMessage to={credPasswordRestore} />,
      }
      break
    case 'passwordReset':
      settings = {
        title: 'Створіть новий пароль',
        className: 'PasswordResetForm',
        component: (
          <PasswordResetForm onPasswordReset={() => setShowAuth('signIn')} />
        ),
      }
      break

    default:
      settings = {
        title: 'Вхід',
        className: 'SignIn',
        component: <SignIn setShowAuth={setShowAuth} />,
      }
      break
  }

  useEffect(() => {
    if (user) {
      if (!user.phone) return setShowAuth('phoneConfirmation')
      if (!user.phoneVerified) return setShowAuth('phoneCodeConfirmation')
    }
  }, [user])

  return showAuth ? (
    <PopUp
      show
      title={settings.title}
      className={`Auth ${settings.className}`}
      close={() => setShowAuth(false)}
    >
      {settings.component}
    </PopUp>
  ) : null
}

export default withRouter(Auth)
