import React from 'react'
import './SitePageHeader.sass'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import Media from 'react-media'

function SitePageHeader({ caption, title, underTitle, rightSide, className }) {
  return (
    <div
      className={['Site-PageHeader', ...(className ? [className] : [])].join(
        ' '
      )}
    >
      <div className="LeftSide">
        {caption && <p className="Caption-Medium">{caption}</p>}
        <SectionTitle title={title} />
        <Media
          query="(max-width: 1366px)"
          render={() => (rightSide ? rightSide : null)}
        />
        {underTitle && underTitle}
      </div>
      <Media
        query="(min-width: 1367px)"
        render={() => (rightSide ? rightSide : null)}
      />
    </div>
  )
}

export default SitePageHeader
