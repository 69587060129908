import React, { useState, useEffect, useRef } from 'react'
import Icon from '../../ui/Icon/Icon'
import getCollection from '../../utils/db/getCollection'
import SitePage from '../SitePage/SitePage'
import parser from 'html-react-parser'
import './Faq.sass'

function Faq() {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    getCollection({ path: 'config/faq/client', docIdName: 'id' }).then((d) =>
      setQuestions(d)
    )
  }, [])

  return (
    <SitePage withFormData>
      <div className="Site-Content Faq-Container">
        <div className="Questions">
          <h2>Часті запитання</h2>
          {questions.map((q) => (
            <Question key={q.id} title={q.title} text={q.text} />
          ))}
        </div>
      </div>
    </SitePage>
  )
}

export function Question({ status, title, text }) {
  const qRef = useRef()

  const onQuestionClick = () => {
    qRef.current.classList.toggle('Question_status_active')
  }

  return (
    <div
      ref={qRef}
      className={[
        'Question',
        ...(status ? ['Question_status_active'] : []),
      ].join(' ')}
    >
      <div className="Question-Header" onClick={onQuestionClick}>
        {title}
        <Icon name="angle-down" />
      </div>
      <div className="Question-Body">{parser(text)}</div>
    </div>
  )
}

export default Faq
