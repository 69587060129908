import React from 'react'
import './Logo.sass'
import { NavHashLink } from 'react-router-hash-link'
import logoWhite from './logoWhite.svg'
import logoMain from './logoMain.svg'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'

function Logo({ navLink = false, path, theme }) {
  let logo = logoMain
  switch (theme) {
    case 'white':
      logo = logoWhite
      break
    case 'main':
      logo = logoMain
      break
    default:
      logo = logoMain
      break
  }

  const logoImg = <img src={logo} alt="OllApp" />
  return navLink ? (
    <NavHashLink
      to={path}
      className="Logo"
      scroll={(el) => scrollWithOffset(el, -64)}
    >
      {logoImg}
    </NavHashLink>
  ) : (
    <a href={path} className="Logo">
      {logoImg}
    </a>
  )
}

export default Logo
