export default function handleSignUpErrors({ code, setNBlockSettings }) {
  switch (code) {
    case 'existByPhoneAndEmail':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Цей телефон і електронна пошта вже зареєстровані',
        content:
          'Схоже, що у вас вже є профіль. Спробуйте увійти або відновити пароль. ',
      })
    case 'existByPhone':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Цей телефон вже зареєстрований',
        content:
          'Схоже, що у вас вже є профіль. Спробуйте увійти або відновити пароль. ',
      })
    case 'existByEmail':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Ця електронна пошта вже зареєстрована',
        content:
          'Схоже, що у вас вже є профіль. Спробуйте увійти або відновити пароль. ',
      })

    default:
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Unhandled error',
        content: `log for dev: ${code}`,
      })
  }
}
