const cartProductObject = ({
  productId,
  locationId,
  categoryId,
  assignment,
  options,
  optionsValues = [],
  quantity,
} = {}) => {
  return {
    productId,
    locationId,
    categoryId,
    assignment,
    options,
    optionsValues,
    quantity,
    version: 1.0,
  }
}
export default cartProductObject
