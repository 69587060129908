import { isEmpty } from 'lodash'
import getCollection from '../../../../utils/db/getCollection'
import promiseAllValues from '../../../../utils/promiseAllValues'

export default function checkUserByPhoneAndEmail({ email, phone }) {
  return promiseAllValues({
    byPhoneAndEmail: getCollection({
      path: 'users',
      docIdName: 'userId',
      whereQueries: [
        { fieldPath: 'email', op: '==', value: email },
        { fieldPath: 'phone', op: '==', value: phone },
      ],
    }),
    byPhone: getCollection({
      path: 'users',
      docIdName: 'userId',
      whereQueries: [{ fieldPath: 'phone', op: '==', value: phone }],
    }),
    byEmail: getCollection({
      path: 'users',
      docIdName: 'userId',
      whereQueries: [{ fieldPath: 'email', op: '==', value: email }],
    }),
  }).then((exist) => {
    if (!isEmpty(exist.byPhoneAndEmail))
      return Promise.reject('existByPhoneAndEmail')
    if (!isEmpty(exist.byPhone)) return Promise.reject('existByPhone')
    if (!isEmpty(exist.byEmail)) return Promise.reject('existByEmail')
    return true
  })
}
