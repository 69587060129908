import React from 'react'
import Icon from '../../../../../ui/Icon/Icon'
import './MapMarker.sass'
import activePin from './mapPin_active.svg'
import defaultPin from './mapPin_default.svg'

function MapMarker({ isActive, title }) {
  return (
    <div
      className={[
        'MapMarker-Container',
        ...(isActive ? ['MapMarker_theme_active'] : []),
      ].join(' ')}
    >
      <div
        className={'MapMarker'}
        style={{ backgroundImage: `url(${isActive ? activePin : defaultPin})` }}
      >
        <Icon name="store" weight="solid" />
      </div>
      <div className="MapMarker-Text">
        <p className="Body-Bold-2">{title}</p>
        <p>Пункт прокату</p>
      </div>
    </div>
  )
}

export default MapMarker
