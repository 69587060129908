import { firebase } from '../../../../config/firebase'
import setDoc from '../../../../utils/db/setDoc'

const signUpUser = (values) => {
  const { email, password } = values

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((result) =>
      setDoc({
        path: 'users',
        docId: result.user.uid,
        data: values,
      })
    )
}

export default signUpUser
