import React, { useState } from 'react'
import uniqid from 'uniqid'
import merchantConfig from '../../config/fondy'
import { functions } from '../../config/firebase'
import generateSignature from './generateSignature'
import Spinner from '../../ui/Spinner/Spinner'
import useScript from '../../hooks/useScript'
import './checkout-vue.css'

function FondyPaymentForm({
  email,
  uid,
  orderDesc,
  amount,
  saveCard,
  onPaymentSuccess,
  onPaymentFailed,
}) {
  const [paymentToke, setPaymentToken] = useState(null)

  const orderId = uniqid('payOrder-')

  const Options = {
    options: {
      methods: ['card'],
      methods_disabled: ['wallets'],
      active_tab: 'card',
      fields: false,
      full_screen: false,
      button: true,
      email: true,
      theme: {
        preset: 'reset',
      },
    },
    css_variable: {
      main: '#5B0E8B',
    },
    params: {
      email,
      lang: 'uk',
    },
  }

  const loadFondyForm = () => {
    const payments = functions.httpsCallable('paymentsOnCall')

    const request = {
      merchant_id: merchantConfig.merchantId,
      order_id: orderId,
      order_desc: orderDesc,
      currency: merchantConfig.currency,
      amount: amount * 100,
      // verification: amount === 0 ? 'Y' : 'N',
      verification: 'N',
      preauth: 'N',
      server_callback_url:
        'https://europe-west3-ollapp-io.cloudfunctions.net/paymentsOnRequest?func=receivePaymentResponse',
      required_rectoken: saveCard ? 'Y' : 'N',
      lang: 'uk',
      merchant_data: JSON.stringify({
        uid,
      }),
    }

    payments({
      func: 'requestPaymentToken',
      data: {
        request: {
          ...request,
          signature: generateSignature(request),
        },
      },
    })
      .then(({ data }) => {
        console.log(
          '🚀 ~ file: FondyPaymentForm.jsx ~ line 76 ~ .then ~ data',
          data
        )
        const { response } = data
        if (response.response_status === 'success') {
          setPaymentToken(response.token)
          Options.params = { ...Options.params, token: response.token }
          const fondy = window.fondy('#fondy-form', Options)
          fondy
            .$on('success', () => onPaymentSuccess())
            .$on('error', () => onPaymentFailed())
        }
      })
      .catch((e) => {
        console.log('fondyPaymentForm getToken Error ->', e)
      })
  }

  useScript(
    'https://pay.fondy.eu/latest/checkout-vue/checkout.js',
    loadFondyForm
  )

  return paymentToke ? <div id="fondy-form"></div> : <Spinner type="module" />
}

export default FondyPaymentForm
