import React from 'react'
import { functions } from '../../../../config/firebase'
import { renderEmail } from 'react-html-email'
import PasswordResetEmail from '../../../../components/Emails/PasswordResetEmail'
import CryptoJS from 'crypto-js'

const sendPasswordRecoveryEmail = ({ to, recoveryCode }) => {
  const func = functions.httpsCallable('serviceEmailsOnCall')
  const messageHtml = renderEmail(
    <PasswordResetEmail recoveryCode={recoveryCode} />
  )

  const encodedWord = CryptoJS.enc.Utf8.parse(messageHtml)
  const encodedHtml = CryptoJS.enc.Base64.stringify(encodedWord)

  return func({
    func: 'sendEmail',
    email: {
      subject: 'Запит на відновлення паролю',
      html: encodedHtml,
      to: [{ email: to }],
    },
  })
}

export default sendPasswordRecoveryEmail
