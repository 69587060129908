import React from 'react'
import { Route, Switch } from 'react-router'
import SitePage from '../SitePage/SitePage'
import CheckoutPageHeader from './CheckoutPageHeader/CheckoutPageHeader'
import PaymentMethods from './PaymentMethods/PaymentMethods'
import PickupPoints from './PickupPoints/PickupPoints'

function Checkout() {
  return (
    <SitePage head={<CheckoutPageHeader />}>
      <Switch>
        <Route path="/checkout/pickup-points" component={PickupPoints} />
        <Route path="/checkout/payment-methods" component={PaymentMethods} />
      </Switch>
    </SitePage>
  )
}

export default Checkout
