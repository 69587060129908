import React from 'react'
import SitePage from '../SitePage/SitePage'
import './Contacts.sass'
import illustration from './contacts.svg'
import Icon from '../../ui/Icon/Icon'

function Contacts() {
  return (
    <SitePage withFormData>
      <div className="Site-Content Contacts-Container">
        <img src={illustration} alt="Contact us" />
        <div className="Text">
          <h2>Контакти</h2>
          <p>Виникли запитання? Ми завжди готові допомогти:)</p>
          <div className="ContactBlock">
            <p className="Title Caption-Medium">Служба турботи про клієнтів</p>
            <div className="ContactLine">
              <div className="Icon-Container">
                <Icon name="phone" weight="solid" />
              </div>
              <a href="tel: +38 (067) 796-17-17" className="Body-Medium-1">
                +38 (067) 796-17-17
              </a>
            </div>
            <div className="ContactLine">
              <div className="Icon-Container">
                <Icon name="envelope" weight="solid" />
              </div>
              <a href="mailto: support@ollapp.com.ua" className="Body-Medium-1">
                support@ollapp.com.ua
              </a>
            </div>
          </div>
        </div>
      </div>
    </SitePage>
  )
}

export default Contacts
