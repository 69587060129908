import React, { useContext, useState, useEffect } from 'react'
import './PaymentMethods.sass'
import Button from '../../../ui/Button/Button'
import NotificationBlock from '../../../ui/NotificationBlock/NotificationBlock'
import Icon from '../../../ui/Icon/Icon'
import Radio from '../../../ui/Field/Radio/Radio'
import OrderDetails from './OrderDetails/OrderDetails'
import { AuthContext } from '../../../App'
import promiseAllValues from '../../../utils/promiseAllValues'
import getDoc from '../../../utils/db/getDoc'
import getCollection from '../../../utils/db/getCollection'
import { find } from 'lodash'
import updateDoc from '../../../utils/db/updateDoc'
import Spinner from '../../../ui/Spinner/Spinner'
import FormRender from '../../../utils/forms/render/FormRender'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import { withRouter } from 'react-router-dom'

function PaymentMethods({ ...router }) {
  const { location, dates, cart } = useContext(AuthContext)
  const [data, setData] = useState(null)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        saveCard: {
          field: {
            fieldId: 'saveCard',
            fieldType: 'checkbox',
            label: 'Зберегти карту для наступних покупок',
            value: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (cart.pickupShop && cart.pickupSpot) {
      promiseAllValues({
        shop: getDoc({
          path: 'shops',
          docId: cart.pickupShop,
          docIdName: 'shopId',
        }),
        products: getCollection({ path: 'products', docIdName: 'productId' }),
      }).then((result) => {
        setData({
          shop: result.shop,
          spot: find(result.shop.spots, ['spotId', cart.pickupSpot]),
          products: result.products,
        })
      })
    }
  }, [cart])

  const onPaymentTypeClicked = ({ paymentType, price }) => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { paymentType, priceToPay: price },
    })
  }

  const onPaymentProceed = () => {
    setIsLoading(true)
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { saveCard: form.saveCard.value },
    }).then(() => router.history.push('/payment/process'))
  }

  return (
    <div className="Site-Content PPaymentMethods">
      <div className="Info">
        <Button
          theme="internal-link"
          title="Повернутися"
          icon="angle-left"
          type="navlink"
          iconPosition="left"
          iconWeight="regular"
          size={32}
          path="/checkout/pickup-points"
        />
      </div>
      <div className="PaymentConfig-Container">
        <OrderDetails
          location={location}
          dates={dates}
          sameDay={localStorage.getItem('sameDay')}
          data={data}
          cart={cart}
        />
        {data ? (
          <div className="PaymentTypes">
            <NotificationBlock
              title="Застава"
              content={`На пункті прокату необхідно залишити грошову заставу або особистий документ (паспорт, водійські права).`}
            />
            <div className="PaymentOptions">
              <PaymentOptionTile
                icon="credit-card"
                title="Оплатити повністю"
                price={cart.price}
                isActive={cart.paymentType === 'full'}
                onClick={() =>
                  onPaymentTypeClicked({
                    paymentType: 'full',
                    price: cart.price,
                  })
                }
              />
              <PaymentOptionTile
                icon="chart-pie-alt"
                title={`Оплатити частково (${data.shop.percent}%) з доплатою на пункті прокату`}
                price={(data.shop.percent * cart.price) / 100}
                isActive={cart.paymentType === 'half'}
                onClick={() =>
                  onPaymentTypeClicked({
                    paymentType: 'half',
                    price: (data.shop.percent * cart.price) / 100,
                  })
                }
              />
              <FormRender
                sections={[{ fields: ['saveCard'] }]}
                form={form}
                setForm={setForm}
              />
            </div>
            <div className="PaymentConfig-NextButton">
              <Button
                title={`Оплатити ${
                  cart.priceToPay ? `${cart.priceToPay} ₴` : ''
                }`}
                size={40}
                disabled={!cart.paymentType}
                onClick={onPaymentProceed}
                isLoading={isLoading}
              />
            </div>
          </div>
        ) : (
          <div className="Spinner-Container">
            <Spinner type="button" theme="gray" />
          </div>
        )}
      </div>
    </div>
  )
}

function PaymentOptionTile({ isActive, icon, title, price, onClick }) {
  return (
    <div
      className={[
        'PaymentOptionTile',
        ...(isActive ? ['PaymentOptionTile_theme_active'] : ['']),
      ].join(' ')}
      onClick={onClick}
    >
      <div className="PaymentIcon">
        <Icon name={icon} weight="regular" />
      </div>
      <div className="Content">
        <p className="Title Body-Regular-2">{title}</p>
        <p className="Price Body-Bold-2">{price} ₴</p>
      </div>
      <Radio value={isActive ? 'on' : 'off'} />
    </div>
  )
}

export default withRouter(PaymentMethods)
