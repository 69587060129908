import React from 'react'
import SitePageHeader from '../../SitePage/SitePageHeader/SitePageHeader'
import Stepper from '../../../ui/Stepper/Stepper'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import './CheckoutPageHeader.sass'

function CheckoutPageHeader({ ...router }) {
  const steps = [
    {
      status:
        router.location.pathname === '/checkout/pickup-points'
          ? 'active'
          : 'done',
      title: 'Пункт прокату',
      path: '/checkout/pickup-points',
    },
    {
      status:
        router.location.pathname === '/checkout/payment-methods'
          ? 'active'
          : 'notActive',
      title: 'Оплата',
      path: '/checkout/payment-methods',
    },
  ]

  return (
    <SitePageHeader
      className="CheckoutPageHeader"
      caption="Оформлення замовлення"
      title={find(steps, ['status', 'active']).title}
      rightSide={<Stepper steps={steps} width="450px" />}
    />
  )
}

export default withRouter(CheckoutPageHeader)
