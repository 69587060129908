import React from 'react'
import Icon from '../../../../ui/Icon/Icon'
import Img from '../../../../ui/Img/Img'
import Radio from '../../../../ui/Field/Radio/Radio'
import './PointTile.sass'
import Button from '../../../../ui/Button/Button'

function PointTile({
  location,
  avatar = null,
  spotId,
  shopName,
  spotAddress,
  tags = [],
  price = 100,
  isActive,
  onClick,
}) {
  return (
    <div className="PointTile">
      <div className="PointTile-Info">
        <div className="Avatar">
          {avatar ? (
            <Img src={avatar} alt={shopName} />
          ) : (
            <div className="Avatar-Icon">
              <Icon name="store" weight="regular" />
            </div>
          )}
        </div>
        <div className="Content">
          <div className="Head">
            <h4>{shopName}</h4>
            <Radio
              value={isActive ? 'on' : 'off'}
              onClick={() => onClick(spotId)}
            />
          </div>
          <p className="Address Body-Regular-2">{`${location.title}, ${spotAddress}`}</p>
        </div>
      </div>
      <div className="Tags">
        {tags.map((t) => (
          <div className="Tag" style={{ backgroundColor: `${t.bgc}` }}>
            <span className="Body-Medium-2">{t.title}</span>
          </div>
        ))}
      </div>
      <p className="Totals">
        <span className="Body-Medium-2">Підсумок:</span>
        <h4 className="Price">{price} ₴</h4>
      </p>
      <div className="ShowOnMap">
        <Button
          icon="map"
          size={32}
          title="Показати на карті"
          iconPosition="left"
          theme="secondary"
          onClick={() => onClick(spotId)}
        />
      </div>
    </div>
  )
}

export default PointTile
