import { cloneDeep, find, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { AuthContext } from '../../App'
import getCollection from '../../utils/db/getCollection'
import SitePage from '../SitePage/SitePage'
import Catalog from './Catalog'
import CatalogPageHeader from './CatalogPageHeader/CatalogPageHeader'
import Totals from './Totals/Totals'

function CatalogIndex({ ...router }) {
  const [subCategories, setSubCategories] = useState([])
  const [assignmentFilter, setAssignmentFilter] = useState('male')
  const { category } = useContext(AuthContext)

  useEffect(() => {
    if (category.id) {
      getCollection({
        path: 'config/products/categories',
        docIdName: 'categoryId',
        whereQueries: [
          { fieldPath: 'parentCategory', op: '==', value: category.id },
        ],
      }).then((cats) => {
        setSubCategories(
          cats.map((c, i) => {
            c.path = `/catalog/${c.categoryId}`
            const curLink = router.match.params.subCategoryId
            const activeCat = find(cats, ['categoryId', curLink])

            if (curLink && curLink !== 'totals' && activeCat) {
              if (c.pos < activeCat.pos) {
                c.status = 'done'
              } else if (c.categoryId === activeCat.categoryId) {
                c.status = 'active'
              } else {
                c.status = 'notActive'
              }
            } else if (curLink === 'totals') {
              c.status = 'done'
            } else {
              c.status = i === 0 ? 'active' : 'notActive'
            }

            return c
          })
        )
      })
    }
  }, [category])

  useEffect(() => {
    const curLink = router.match.params.subCategoryId

    if (curLink && !isEmpty(subCategories)) {
      if (curLink === 'totals') {
        setSubCategories(
          cloneDeep(subCategories).map((c) => {
            c.status = 'done'
            return c
          })
        )
      } else {
        const nextActiveCategory = find(subCategories, ['categoryId', curLink])
        setSubCategories(
          subCategories.map((c) => {
            c.path = `/catalog/${c.categoryId}`
            if (c.pos < nextActiveCategory.pos) {
              c.status = 'done'
            } else if (c.categoryId === nextActiveCategory.categoryId) {
              c.status = 'active'
            } else {
              c.status = 'notActive'
            }
            return c
          })
        )
      }
      setAssignmentFilter('male')
    }
  }, [router.match.params])

  return (
    <SitePage
      head={
        <CatalogPageHeader
          categories={subCategories}
          assignmentFilter={assignmentFilter}
          setAssignmentFilter={setAssignmentFilter}
        />
      }
      withFormData
    >
      <Switch>
        <Route path="/catalog/totals" exact component={Totals} />
        <Route
          path={['/catalog', '/catalog/:subCategoryId']}
          exact
          render={() => (
            <Catalog
              subCategories={subCategories}
              assignmentFilter={assignmentFilter}
            />
          )}
        />
      </Switch>
    </SitePage>
  )
}

export default withRouter(CatalogIndex)
