import React from 'react'
import { Item, Span } from 'react-html-email'
import EmailBody from './EmailBody/EmailBody'
import EmailActionButton from './EmailActionButton'

function PasswordResetEmail({ recoveryCode }) {
  return (
    <EmailBody>
      <Item align="center">
        <Span
          style={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Встановіть новий пароль
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '8px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Ми отримали запит на відновлення вашого пароля. Скористайтесь
          посиланням нижче або скопіюйте та вставте його у свій браузер, щоб
          встановити новий пароль.
        </Span>
      </Item>
      <EmailActionButton
        style={{
          paddingTop: '24px',
          alignSelf: 'center',
        }}
        title="Встановити новий пароль"
        link={`https://ollapp.com.ua/password-recovery?recoveryCode=${recoveryCode}`}
      />
      <Item
        style={{
          paddingTop: '24px',
        }}
      >
        <Span
          style={{
            display: 'block',
            width: '100%',
            height: '1px',
            backgroundColor: '#E2E6EB',
          }}
        />
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '12px',
        }}
      >
        <Span
          style={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '18px',
            color: '#484F56',
          }}
        >
          Якщо у вас виникли запитання, будь ласка, напишіть нам на{' '}
          <a href="mailto: support@ollapp.com.ua" style={{ color: '#5B0E8B' }}>
            support@ollapp.com.ua
          </a>
        </Span>
      </Item>
    </EmailBody>
  )
}

export default PasswordResetEmail
