import { find, isEmpty, uniq } from 'lodash'
import React, {
  useContext,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { AuthContext } from '../../../App'
import getCollection from '../../../utils/db/getCollection'
import promiseAllValues from '../../../utils/promiseAllValues'
import CartItem from '../CartItem/CartItem'
import ItemsTableHead from '../ItemsTableHead/ItemsTableHead'
import './CartList.sass'
import { cloneDeep } from 'lodash'
import uniqid from 'uniqid'

const CartList = forwardRef((props, ref) => {
  const { cart, location } = useContext(AuthContext)
  const [productsData, setProductsData] = useState(null)
  const productsRefs = []
  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    promiseAllValues({
      products: getCollection({ path: 'products', docIdName: 'productId' }),
      params: getCollection({
        path: 'config/products/productParams',
        docIdName: 'paramId',
      }),
      shops: getCollection({
        path: 'shops',
        docIdName: 'shopId',
      }),
    }).then((result) => {
      const spots = []
      result.shops.forEach((s) => {
        s.spots.forEach((spot) => {
          if (spot.spotCity === location.id) {
            spots.push(spot.spotId)
          }
        })
      })
      setProductsData({
        products: result.products,
        params: result.params,
        spots: uniq(spots),
      })
    })
  }, [])

  useImperativeHandle(ref, () => ({
    onCheckout() {
      const allFormsAreValid = []
      productsRefs.forEach((r) => {
        allFormsAreValid.push(r.current.checkFormValidity())
      })

      const cartProductsClone = cloneDeep(cart.products).map((p) => {
        p.optionsValues = []
        return p
      })

      productsRefs.forEach((r) => {
        const data = r.current.getValues()
        const product = find(cartProductsClone, ['productId', data.productId])
        product.optionsValues.push(data.values)
      })

      let data = {
        status: false,
        products: cartProductsClone,
      }

      if (allFormsAreValid.every((e) => e && true)) {
        data.status = true
        setShowErrors(false)
      } else {
        data.status = false
        setShowErrors(true)
      }
      return data
    },
  }))

  return (
    <div className="CartList">
      {!isEmpty(cart.products) && !isEmpty(productsData) ? (
        <>
          <ItemsTableHead />
          {cart.products.map((p) => {
            const productByQuantity = []
            for (let index = 0; index < p.quantity; index++) {
              const pRef = React.createRef()

              productByQuantity.push(
                <CartItem
                  key={`${p.productId}-${uniqid()}`}
                  ref={pRef}
                  {...p}
                  productData={find(productsData.products, [
                    'productId',
                    p.productId,
                  ])}
                  spotsByLocation={productsData.spots}
                  productParams={productsData.params}
                  productIndex={index}
                  optionsValues={p.optionsValues[index] || {}}
                  cartProductData={p}
                  showErrors={showErrors}
                />
              )
              productsRefs.push(pRef)
            }
            return productByQuantity
          })}
        </>
      ) : (
        <p className="EmptyText">Немає товарів</p>
      )}
    </div>
  )
})

export default CartList
