import React from 'react'
import './PickupPointsNextStep.sass'
import Button from '../../../../ui/Button/Button'

function PickupPointsNextStep({ title, address, onPickupDrop }) {
  return (
    <div className="PickupPointsNextStep">
      <Button theme="secondary" size={24} icon="times" onClick={onPickupDrop} />
      <div className="Info">
        <h4>{title}</h4>
        <p className="Address Body-Regular-2">{address}</p>
      </div>
      <Button
        theme="primary"
        title="Далі"
        size={40}
        className="NextStep"
        type="navlink"
        path={'/checkout/payment-methods'}
      />
    </div>
  )
}

export default PickupPointsNextStep
