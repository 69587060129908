import React from 'react'
import './CookieNotification.sass'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'

function CookieNotification() {
  return (
    <CookieConsent
      location="bottom"
      containerClasses="CookieNotification"
      buttonClasses="CookieNotification-Button"
      buttonText="Погоджуюсь"
      cookieName="CookieNotification"
    >
      <Icon name="cookie-bite" />
      Це стандартне повідомлення про використання файлів cookie. Користуючись
      сайтом, ви погоджуєтесь з{' '}
      <Link to="/privacy-policy">Політикою конфіденційності.</Link>
    </CookieConsent>
  )
}

export default CookieNotification
