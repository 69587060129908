import React from 'react'
import Logo from '../../ui/Logo/Logo'
import CartButton from '../Cart/CartButton/CartButton'
import LangPicker from '../LangPicker/LangPicker'
import ProfileButton from '../Profile/ProfileButton/ProfileButton'
import './Header.sass'
import { hTitle, hDescription } from '../../pages/Home/HowItWorks'
import HeaderFormData from './HeaderFormData/HeaderFormData'
import Media from 'react-media'

function Header({ white, withCaption, withFormData }) {
  return (
    <>
      <section
        className={['Header', ...(white ? ['Header_theme_white'] : [''])].join(
          ' '
        )}
      >
        <div className="LeftSide">
          <Logo theme={white ? 'main' : 'white'} navLink path="/" />
          {withCaption && (
            <div className="Header-Caption">
              <p className="Caption-Medium">{hTitle}</p>
              <p className="Overline-Medium">{hDescription}</p>
            </div>
          )}
        </div>
        <div className="Header-FormData">
          <Media
            query="(min-width: 1366px)"
            render={() => (withFormData ? <HeaderFormData /> : null)}
          />
        </div>
        <div className="Header-Controlls">
          <CartButton />
          <div className="Devider" />
          <ProfileButton />
          {/* <LangPicker /> */}
        </div>
      </section>
      <Media
        query="(max-width: 1366px)"
        render={() => (withFormData ? <HeaderFormData /> : null)}
      />
    </>
  )
}

export default Header
