import { isEmpty, find } from 'lodash'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'
import UserObject from '../../../Auth/SignUp/functions/userObject'

const createProductFormPattern = ({ productData, productParams, options }) => {
  const object = {}

  if (!isEmpty(productData.params)) {
    productData.params.forEach((p) => {
      if (p.param)
        createFieldPattern({
          object,
          paramId: p.param,
          prefix: 'main',
          productParams,
        })
    })
  }

  if (!isEmpty(options)) {
    options.forEach((o) => {
      const optionData = find(productData.options, ['id', o])
      if (optionData.param) {
        createFieldPattern({
          object,
          paramId: optionData.param,
          prefix: `${o}`,
          productParams,
        })
      }
    })
  }

  return object
}

const createFieldPattern = ({ object, paramId, prefix, productParams }) => {
  const paramData = find(productParams, ['paramId', paramId])

  switch (paramData.type) {
    case 'string':
      object[`${prefix}-${paramId}`] = {
        field: {
          fieldId: `${prefix}-${paramId}`,
          fieldType: 'input',
          inputType: 'text',
          label: paramData.label,
          placeholder: paramData.label,
          required: true,
        },
        render: getFieldRenderObject(),
      }
      break
    case 'options':
      object[`${prefix}-${paramId}`] = {
        field: {
          fieldId: `${prefix}-${paramId}`,
          fieldType: 'select',
          label: paramData.label,
          placeholder: paramData.label,
          getOptions: paramData.options,
          required: true,
        },
        render: getFieldRenderObject(),
      }
      break
    default:
      break
  }
}

export default createProductFormPattern
