import React, { useContext, useRef } from 'react'
import Icon from '../../../ui/Icon/Icon'
import './CartButton.sass'
import { AuthContext } from '../../../App'
import CartPopUp from '../CartPopUp/CartPopUp'
import { usePopUp } from '../../../hooks/usePopUp'
import { sum } from 'lodash'

function CartButton() {
  const { cart } = useContext(AuthContext)
  const butRef = useRef()
  const pRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(butRef, pRef)

  return (
    <div className="CartButton-Container">
      <div ref={butRef} className="CartButton">
        {cart.products.length ? (
          <span className="CartCounter Overline-Medium">
            {sum(cart.products.map((p) => p.quantity))}
          </span>
        ) : null}
        <Icon name="shopping-cart" weight="regular" />
      </div>
      {showPopUp && (
        <div ref={pRef}>
          <CartPopUp close={() => setShowPopUp(false)} />
        </div>
      )}
    </div>
  )
}

export default CartButton
