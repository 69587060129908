import React, { useContext, useEffect, useState, useRef } from 'react'
import './Totals.sass'
import Button from '../../../ui/Button/Button'
import { find, isEmpty, uniq } from 'lodash'
import { withRouter } from 'react-router-dom'
import defineProductPrice from '../ProductTile/functions/defineProductPrice'
import CartList from '../../../components/Cart/CartList/CartList'
import { AuthContext } from '../../../App'
import promiseAllValues from '../../../utils/promiseAllValues'
import getCollection from '../../../utils/db/getCollection'
import DatePickerPopUp from '../../Checkout/DatePickerPopUp/DatePickerPopUp'
import updateDoc from '../../../utils/db/updateDoc'
import { differenceInDays } from 'date-fns'
import defineTotalPrice from './defineTotalPrice'

function Totals({ ...router }) {
  const { user, cart, setCategory, dates, location, setShowAuth } = useContext(
    AuthContext
  )
  const [productsData, setProductsData] = useState({
    products: null,
    params: null,
    spots: null,
  })

  const [isLoading, setIsLoading] = useState(false)
  const prodListRef = useRef()

  useEffect(() => {
    if (location.id) {
      promiseAllValues({
        products: getCollection({ path: 'products', docIdName: 'productId' }),
        params: getCollection({
          path: 'config/products/productParams',
          docIdName: 'paramId',
        }),
        shops: getCollection({
          path: 'shops',
          docIdName: 'shopId',
        }),
      }).then((result) => {
        const spots = []
        result.shops.forEach((s) => {
          s.spots.forEach((spot) => {
            if (spot.spotCity === location.id) {
              spots.push(spot.spotId)
            }
          })
        })
        setProductsData({
          products: result.products,
          params: result.params,
          spots: uniq(spots),
        })
      })
    }
  }, [location])

  const changeRootCategory = (categoryId, title) => {
    const result = prodListRef.current.onCheckout()
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { products: result.products },
    }).then(() => {
      localStorage.setItem('category', categoryId)
      // setCategory({ id: categoryId, title })
      window.location.href = `/catalog`
    })
  }

  const onCheckout = () => {
    const result = prodListRef.current.onCheckout()
    setIsLoading(true)
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { products: result.products },
    }).then(() => {
      setIsLoading(false)
      if (!user) {
        return setShowAuth('signUp')
      } else {
        if (result.status) router.history.push('/checkout/pickup-points')
      }
    })
  }

  return productsData.products ? (
    <div className="Site-Content Totals">
      {!dates.start && !dates.end && (
        <DatePickerPopUp title="Оберіть термін прокату" />
      )}
      <div className="Totals-Products">
        <CartList ref={prodListRef} />
      </div>
      <div className="TotalsPanel">
        <div className="PriceBlock">
          <p className="TotalsText Body-Medium-1">Всього:</p>
          <h2 className="Price">
            {defineTotalPrice({
              cart,
              dates,
              productsData: productsData.products,
              spotsByLocation: productsData.spots,
            })}{' '}
            ₴*
          </h2>
          <p className="TotalsCaption Caption-Regular">
            * Фінальна вартість залежить від обраного пункту прокату
          </p>
        </div>
        <div className="Totals-Button">
          <Button
            title="Перейти до оформлення"
            isLoading={isLoading}
            onClick={onCheckout}
            disabled={isEmpty(cart.products)}
          />
        </div>
        <div className="OfftopSeparator Body-Regular-2">
          <span className="Separator" />
          <span>Обрати ще для</span>
          <span className="Separator" />
        </div>
        <div className="Totals-CatalogButtons">
          <Button
            title="Сноубордингу"
            icon="snowboarding"
            theme="secondary"
            size={40}
            onClick={() =>
              changeRootCategory('xd8nVtWNudjeeRwxC90z', 'Сноубординг')
            }
          />
          <Button
            title="Лижного спорту"
            icon="skiing"
            theme="secondary"
            size={40}
            onClick={() =>
              changeRootCategory('mZLx1rn2ZWlX7gLXGCR7', 'Лижний спорт')
            }
          />
        </div>
      </div>
    </div>
  ) : null
}

export default withRouter(Totals)
