import React, { useContext, useState } from 'react'
import PasswordStrengthBar from '../../../Auth/SignUp/PasswordStrengthBar/PasswordStrengthBar'
import PopUp from '../../../../ui/PopUp/PopUp'
import { createForm } from '../../../../utils/forms/createForm'
import FormRender from '../../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'
import './PasswordResetPopUp.sass'
import Button from '../../../../ui/Button/Button'
import getFormValues from '../../../../utils/forms/getFormValues'
import isFormValid from '../../../../utils/forms/validation/isFormValid'
import { AuthContext } from '../../../../App'
import { firebase } from '../../../../config/firebase'
import updateDoc from '../../../../utils/db/updateDoc'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../../ui/NotificationBlock/NotificationBlock'

function PasswordResetPopUp({ close, onPasswordUpdated }) {
  const { user } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            label: 'Старий пароль',
            placeholder: 'Ваш пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
        newPassword: {
          field: {
            fieldId: 'newPassword',
            fieldType: 'input',
            inputType: 'password',
            label: 'Новий пароль',
            placeholder: 'Новий пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()

  const onSubmit = (e) => {
    e.preventDefault()
    const values = getFormValues({ form })

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      if (values.password !== user.password) {
        return setNBlockSettings({
          show: true,
          theme: 'error',
          title: 'Помилка',
          content: 'Пароль не співпадає, дайте текст',
        })
      }

      setShowErrors(false)
      setIsLoading(true)
      setNBlockSettings({ show: false })

      firebase
        .auth()
        .currentUser.updatePassword(values.newPassword)
        .then(() =>
          updateDoc({
            path: 'users',
            docId: user.uid,
            data: { password: values.newPassword },
          })
        )
        .then(() => onPasswordUpdated())
        .catch((e) => {
          console.log(
            '🚀 ~ file: PasswordResetPopUp.jsx ~ line 70 ~ onSubmit ~ e',
            e
          )
        })
    }
  }

  return (
    <PopUp
      show
      title={'Зміна паролю'}
      className={`EditProfile-PasswordResetPopUp`}
      close={close}
    >
      <div className="PasswordResetPopUp-Container">
        {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
        <div className="PasswordResetPopUp-Fields">
          <FormRender
            sections={[{ fields: ['password', 'newPassword'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <PasswordStrengthBar password={form.newPassword.value} />
        <Button
          title="Зберегти новий пароль"
          theme="primary"
          size={40}
          isLoading={isLoading}
          iconPosition="right"
          onClick={onSubmit}
        />
      </div>
    </PopUp>
  )
}

export default PasswordResetPopUp
