import React, { useState, useRef, useEffect } from 'react'
import SearchInput, { CustomDdlistElement } from '../SearchInput/SearchInput'
import './LocationChooser.sass'
import { usePopUp } from '../../../hooks/usePopUp'
import getCollection from '../../../utils/db/getCollection'
import { isEmpty, orderBy, find, cloneDeep } from 'lodash'
import Spinner from '../../../ui/Spinner/Spinner'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function LocationChooser() {
  const [locations, setLocations] = useState(null)
  const butRef = useRef()
  const ddRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(butRef, ddRef)
  const { setLocation } = useContext(AuthContext)

  useEffect(() => {
    getCollection({
      docIdName: 'id',
      path: 'cities',
    }).then((result) => {
      setLocations(
        orderBy(result, ['title'], ['asc']).map((l) => {
          l.isActive = localStorage.getItem('location') === l.id
          return l
        })
      )
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(locations)) {
      const activeLocation = find(locations, ['isActive', true])
      if (activeLocation) {
        setLocation(activeLocation)
      } else {
        setLocation({
          title: null,
          id: null,
          lat: null,
          lng: null,
        })
      }
    }
  }, [locations])

  const onLocationClicked = (locationId) => {
    setShowPopUp(false)
    const locationsClone = cloneDeep(locations)
    setLocations(
      locationsClone.map((l) => {
        l.isActive = locationId === l.id
        return l
      })
    )
  }

  const onValueDrop = () => {
    const locationsClone = cloneDeep(locations)
    localStorage.removeItem('location')
    setLocations(
      locationsClone.map((c) => {
        c.isActive = false
        return c
      })
    )
  }

  return (
    <div className="LocationChooser">
      <SearchInput
        ref={butRef}
        title="Місто або курорт"
        placeholder="Де заберете?"
        dropdownElements={
          showPopUp ? (
            <div ref={ddRef} className="DropdownList_custom">
              <div className="List">
                {!isEmpty(locations) ? (
                  locations.map((l) => (
                    <CustomDdlistElement
                      icon="map-marker-alt"
                      weight="regular"
                      {...l}
                      onClick={() => onLocationClicked(l.id)}
                    />
                  ))
                ) : (
                  <Spinner type="popup" />
                )}
              </div>
            </div>
          ) : null
        }
        value={
          find(locations, ['isActive', true])
            ? find(locations, ['isActive', true]).title
            : null
        }
        onValueDrop={onValueDrop}
      />
    </div>
  )
}

export default LocationChooser
