import React, { useState } from 'react'
import './HomeSearch.sass'
import SportChooser from './SportChooser/SportChooser'
import LocationChooser from './LocationChooser/LocationChooser'
import DateChooser from './DateChooser/DateChooser'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../App'
import { useContext } from 'react'
import Media from 'react-media'
import Icon from '../../ui/Icon/Icon'
import MobileStepper from './MobileStepper/MobileStepper'

function HomeSearch({ ...router }) {
  const { category, location, setDates } = useContext(AuthContext)
  const [showMobileStepper, setShowMobileStepper] = useState(false)

  const onSubmit = ({ start, end, flexibleDates }) => {
    if (category.id) localStorage.setItem('category', category.id)
    if (location.id) localStorage.setItem('location', location.id)

    if (flexibleDates) {
      localStorage.removeItem('date')
      localStorage.removeItem('sameDay')
      setDates({ start: null, end: null })
    } else {
      if (start && end) {
        setDates({ start, end })
        localStorage.setItem('date', JSON.stringify({ start, end }))
      }
    }

    router.history.push('/catalog')
  }

  return (
    <>
      <Media
        query="(max-width: 1366px)"
        render={() => (
          <div
            className="HomeSearch"
            onClick={() => setShowMobileStepper(true)}
          >
            <div className="HomeSearch-Mobile">
              <Icon name="search" />
              <span className="Body-Medium-2">Вид спорту</span>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 1366px)"
        render={() => (
          <div className="HomeSearch">
            <SportChooser />
            <LocationChooser />
            <DateChooser onSubmit={onSubmit} />
          </div>
        )}
      />
      <Media
        query="(max-width: 1366px)"
        render={() =>
          showMobileStepper ? (
            <div>
              <MobileStepper onClose={() => setShowMobileStepper(false)} />
            </div>
          ) : null
        }
      />
    </>
  )
}

export default withRouter(HomeSearch)
