import { differenceInDays } from 'date-fns'
import { difference, findIndex, min } from 'lodash'

const defineProductPrice = ({
  productData,
  dateStart = null,
  dateEnd = null,
  sameDay = false,
  spotsByLocation = [],
  cartProductData = null,
}) => {
  const values = []

  Object.values(productData.price)
    .filter((spot) => spotsByLocation.includes(spot.spotId))
    .forEach((data) => {
      if (!cartProductData) {
        values.push(data.priceRanges[0].kitPrice)
      } else {
        const choosedOptions = []
        cartProductData.options.forEach((oI) => {
          choosedOptions.push(
            `option${findIndex(productData.options, ['id', oI])}`
          )
        })

        let price = 0
        //define if this is kit
        if (data.productKitIs.every((elem) => choosedOptions.includes(elem))) {
          price += +data.priceRanges[0].kitPrice

          //add price for another options
          difference(choosedOptions, data.productKitIs).forEach((op) => {
            price += +data.priceRanges[0][`${op}Price`]
          })

          values.push(price)
        } else {
          choosedOptions.forEach((op) => {
            if (op === 'main') {
              price += +data.priceRanges[0].productPrice
            } else {
              price += +data.priceRanges[0][`${op}Price`]
            }
          })
          values.push(price)
        }
      }

      // data.priceRanges.forEach((r) => {
      //   if (r.kitPrice) {
      //     values.push(r.kitPrice)
      //   }
      // })
    })

  const minValue = min(values)
  let total = minValue
  if (dateStart && dateEnd) {
    let days = differenceInDays(dateEnd, dateStart)
    if (!sameDay) days += 1
    total = total * days
  }

  return total
}

export default defineProductPrice
