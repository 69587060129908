import sendConfirmationSms from './sendConfirmationSms'
import updateDoc from '../../../../utils/db/updateDoc'

const genNewConfirmationSms = ({ uid, phoneNumber }) => {
  const confirmationCode = (Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)

  return updateDoc({
    path: 'users',
    docId: uid,
    data: { phoneVerificationCode: confirmationCode },
  })
    .then(() => sendConfirmationSms({ to: phoneNumber, confirmationCode }))
    .then((res) => res.data)
    .catch(() => ({ result: false }))
}

export default genNewConfirmationSms
