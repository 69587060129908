import React, { forwardRef, useState } from 'react'
import './CartItem.sass'
import Button from '../../../ui/Button/Button'
import GenderIcon from '../../../pages/Catalog/ProductTile/GenderIcon/GenderIcon'
import Img from '../../../ui/Img/Img'
import { createForm } from '../../../utils/forms/createForm'
import createProductFormPattern from './functions/createProductFormPattern'
import { cloneDeep } from 'lodash'
import FieldRender from '../../../utils/forms/render/FieldRender'
import defineProductPrice from '../../../pages/Catalog/ProductTile/functions/defineProductPrice'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../utils/forms/getFormValues'
import updateDoc from '../../../utils/db/updateDoc'
import { useImperativeHandle } from 'react'
import { AuthContext } from '../../../App'
import { useContext } from 'react'

const CartItem = forwardRef(
  (
    {
      productId,
      assignment,
      productData,
      productParams,
      options,
      productIndex,
      cartProductData,
      spotsByLocation,
      optionsValues = {},
      showErrors,
    },
    ref
  ) => {
    const [form, setForm] = useState(
      createForm({
        formPattern: createProductFormPattern({
          productData,
          productParams,
          options,
        }),
        formData: optionsValues,
      })
    )

    const { dates, cart } = useContext(AuthContext)

    const paramsToRender = productData.params
      .filter((p) => p.param && true)
      .map((p) => `main-${p.param}`)

    useImperativeHandle(ref, () => ({
      checkFormValidity() {
        return isFormValid({ form })
      },
      getValues() {
        return {
          productId,
          values: getFormValues({ form }),
        }
      },
    }))

    const onDelete = () => {
      const cartProductDataClone = cloneDeep(cartProductData)

      if (cartProductDataClone.quantity === 1) {
        const products = cart.products.filter((p) => {
          if (p.productId === productId && p.assignment === assignment)
            return false
          return true
        })
        updateDoc({ path: 'carts', docId: cart.id, data: { products } })
      } else {
        cartProductDataClone.quantity -= 1
        cartProductDataClone.optionsValues.splice(productIndex, 1)
        updateDoc({
          path: 'carts',
          docId: cart.id,
          data: {
            products: cart.products.map((p) => {
              if (p.productId === productId && p.assignment === assignment) {
                return cartProductDataClone
              } else {
                return p
              }
            }),
          },
        })
      }
    }

    return (
      <div className="CartItem">
        <div className="CartItem-Head">
          <div className="LeftSide">
            <GenderIcon type={assignment} />
            <h4 className="Title">{productData.frontTitle}</h4>
          </div>
          <Button
            theme="internal-link"
            icon="trash"
            size={32}
            onClick={onDelete}
          />
        </div>
        <div className="CartItem-Content">
          {/* <div className="Position">
            <div className="Avatar">
              <Img src={productData.avatar.publicUrl} />
            </div>
            <div className="Data">
              {!isEmpty(productData.params) && !isEmpty(paramsToRender) ? (
                <FormRender
                  sections={[{ fields: paramsToRender }]}
                  form={form}
                  setForm={setForm}
                  errors={showErrors}
                />
              ) : (
                'Підбирається індивідуально у пункті прокату'
              )}
            </div>
          </div> */}
          {productData.options
            .filter((o) => options.includes(o.id))
            .map((o) => {
              return (
                <div className="Position">
                  <div className="Avatar">
                    <Img src={o.avatar.publicUrl} />
                  </div>
                  <div className="Data Body-Regular-2">
                    {o.param ? (
                      <FieldRender
                        key={`${o.id}`}
                        field={{
                          ...form[`${o.id}-${o.param}`],
                          fieldId: `${o.id}-${o.param}`,
                        }}
                        form={form}
                        setForm={setForm}
                        showErrors={showErrors}
                      />
                    ) : (
                      'Підбирається індивідуально у пункті прокату'
                    )}
                  </div>
                </div>
              )
            })}
        </div>
        <div className="PriceLine">
          <span className="Body-Medium-2">За комплект:</span>
          <span className="Body-Medium-1">
            {defineProductPrice({
              productData,
              dateStart: dates.start,
              dateEnd: dates.end,
              sameDay: localStorage.getItem('sameDay') ? true : false,
              spotsByLocation,
              cartProductData,
            })}{' '}
            ₴*
          </span>
        </div>
      </div>
    )
  }
)

export default CartItem
