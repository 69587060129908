import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './ProfileNav.sass'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'

export const profileLinks = [
  {
    icon: 'user',
    title: 'Мій профіль',
    isActive: true,
    path: '/profile/edit-profile',
  },
  {
    icon: 'box-full',
    title: 'Мої замовлення',
    isActive: false,
    path: '/profile/orders',
  },
  {
    icon: 'wallet',
    title: 'Способи оплати',
    isActive: false,
    path: '/profile/payment-methods',
  },
]

function ProfileNav() {
  return (
    <div className="ProfileNav">
      {profileLinks.map((l) => (
        <ProfileNavItem {...l} />
      ))}
    </div>
  )
}

export const ProfileNavItem = withRouter(
  ({ icon, title, isActive, path = null, onClick = () => null, ...router }) => {
    const item = (
      <div
        className={[
          'ProfileNavItem',
          // ...(isActive ? ['ProfileNavItem_theme_active'] : []),
        ].join(' ')}
        onClick={onClick}
      >
        <Icon name={icon} />
        <span className="Body-Medium-2 Title">{title}</span>
        <Icon name="angle-right" className="AngleRight" />
      </div>
    )

    return path ? (
      <NavLink to={path} activeClassName="ProfileNavItem_theme_active">
        {item}
      </NavLink>
    ) : (
      item
    )
  }
)

export default ProfileNav
