import React, { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'
import Button from '../../../../ui/Button/Button'
import Icon from '../../../../ui/Icon/Icon'
import Img from '../../../../ui/Img/Img'
import './PickupPointMobile.sass'
import LocationsMap from '../LocationsMap/LocationsMap'
import useScrollLock from '../../../../hooks/useScrollLock'

function PickupPointMobile({ point, onPickupDrop }) {
  const { location } = useContext(AuthContext)
  useScrollLock()

  return (
    <div className="PickupPointMobile">
      <div className="CloseButton" onClick={onPickupDrop}>
        <Icon name="times" />
      </div>
      <LocationsMap
        defaultCenter={{ lat: +point.lat, lng: +point.lng }}
        zoom={16}
        points={[point]}
      />
      <div className="PointTile">
        <div className="PointTile-Info">
          <div className="Avatar">
            {point.avatar ? (
              <Img src={point.avatar} alt={point.shopName} />
            ) : (
              <div className="Avatar-Icon">
                <Icon name="store" weight="regular" />
              </div>
            )}
          </div>
          <div className="Content">
            <div className="Head">
              <h4>{point.shopName}</h4>
            </div>
            <p className="Address Body-Regular-2">{`${location.title}, ${point.spotAddress}`}</p>
          </div>
        </div>
        {/* <div className="Tags">
          {point.tags.map((t) => (
            <div className="Tag" style={{ backgroundColor: `${t.bgc}` }}>
              <span className="Body-Medium-2">{t.title}</span>
            </div>
          ))}
        </div> */}
        <p className="Totals">
          <span className="Body-Medium-2">Підсумок:</span>
          <h4 className="Price">{point.price} ₴</h4>
        </p>
        <div className="Buttons">
          <Button
            size={40}
            title="Обрати і продовжити"
            type="navlink"
            path={'/checkout/payment-methods'}
          />
        </div>
      </div>
    </div>
  )
}

export default PickupPointMobile
