import React, { useState } from 'react'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../utils/forms/render/FormRender'
import Button from '../../../ui/Button/Button'
import SocialAuth from '../SocialAuth/SocialAuth'
import './SignUp.sass'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../ui/NotificationBlock/NotificationBlock'
import PasswordStrengthBar from './PasswordStrengthBar/PasswordStrengthBar'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../utils/forms/getFormValues'
import checkUserByPhoneAndEmail from './functions/checkUserByPhoneAndEmail'
import handleSignUpErrors from './functions/handleSignUpErrors'
import signUpUser from './functions/signUpUser'
import UserObject from './functions/userObject'

function SignUp({ setShowAuth }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: new UserObject(),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()

  const onSubmit = (e) => {
    e.preventDefault()

    const values = getFormValues({ form })

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      checkUserByPhoneAndEmail({ phone: values.phone, email: values.email })
        .then(() => signUpUser(values))
        .catch((e) => {
          setIsLoading(false)
          handleSignUpErrors({ code: e, setNBlockSettings })
        })
    }
  }

  return (
    <>
      {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
      <form action="" className="SignUp-Form">
        <div className="SignUp-Fields">
          <FormRender
            sections={[
              { fields: ['name', 'lastName', 'phone', 'email'] },
              { fields: ['password'] },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <PasswordStrengthBar password={form.password.value} />
        <Button
          title="Створити профіль"
          theme="primary"
          size={40}
          isLoading={isLoading}
          iconPosition="right"
          onClick={onSubmit}
        />
        <SocialAuth title="або увійти через" close={() => setShowAuth(false)} />
        <p className="AuthLinking">
          <span className="Caption-Regular">
            Реєструючись, ви погоджуєтесь з
          </span>{' '}
          <Button
            title="Умовами використання."
            theme="internal-link"
            size={40}
          />
        </p>
        <p className="AuthLinking">
          <span className="Caption-Regular">Вже маєте профіль?</span>{' '}
          <Button
            title="Увійти"
            theme="internal-link"
            size={40}
            onClick={() => setShowAuth('signIn')}
          />
        </p>
      </form>
    </>
  )
}

export default SignUp
