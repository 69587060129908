import React, { useState, useContext, useEffect } from 'react'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../utils/forms/render/FormRender'
import Button from '../../../ui/Button/Button'
import SocialAuth from '../SocialAuth/SocialAuth'
import './SignIn.sass'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../ui/NotificationBlock/NotificationBlock'
import getFormValues from '../../../utils/forms/getFormValues'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import { firebase } from '../../../config/firebase'
import handleSignInErrors from './functions/handleSignInErrors'
import { AuthContext } from '../../../App'
import setDoc from '../../../utils/db/setDoc'
import { omit } from 'lodash'

function SignIn({ setShowAuth }) {
  const { user } = useContext(AuthContext)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()
  useEffect(() => {
    if (user && user.passwordRecoveryLock) {
      setDoc({
        path: 'users',
        docId: user.uid,
        data: omit(user, [
          'uid',
          'passwordRecoveryCode',
          'passwordRecoveryLock',
          'passwordRecoveryPage',
        ]),
      }).then(() =>
        firebase
          .auth()
          .signOut()
          .then(() => {
            setNBlockSettings({
              show: true,
              theme: 'success',
              title: 'Встановлено новий пароль',
              content:
                'Ви успішно встановили новий пароль. Залишилося увійти у профіль.',
            })
            setTimeout(() => setNBlockSettings({ show: false }), 3000)
          })
      )
    }
  }, [user])

  const [form, setForm] = useState(
    createForm({
      formPattern: {
        email: {
          field: {
            fieldId: 'email',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            label: 'Електронна пошта',
            placeholder: 'sample@gmail.com',
            required: true,
          },
          render: getFieldRenderObject(),
        },
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            label: 'Пароль',
            placeholder: 'Введіть ваш пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  const onSubmit = (e) => {
    e.preventDefault()

    const values = getFormValues({ form })

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => setShowAuth(false))
        .catch(({ code, message }) => {
          setIsLoading(false)
          handleSignInErrors({
            code,
            message,
            setNBlockSettings,
          })
        })
    }
  }

  return (
    <div className="SignIn">
      {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
      <form className="SignIn-Form" onSubmit={onSubmit}>
        <FormRender
          sections={[{ fields: ['email', 'password'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="ForgotPassword">
          <Button
            title="Забули пароль?"
            theme="internal-link"
            onClick={() => setShowAuth('passwordRecovery')}
          />
        </div>
        <Button
          title="Увійти"
          theme="primary"
          size={40}
          onClick={onSubmit}
          isLoading={isLoading}
          iconPosition="right"
          type="submit"
        />
        <SocialAuth title="або увійти через" close={() => setShowAuth(false)} />
        <p className="AuthLinking">
          <span className="Caption-Regular">Не маєте профілю?</span>{' '}
          <Button
            title="Зареєструватися"
            theme="internal-link"
            size={40}
            onClick={() => setShowAuth('signUp')}
          />
        </p>
      </form>
    </div>
  )
}

export default SignIn
