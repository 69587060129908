import React, { useContext, useEffect, useState } from 'react'
import './Catalog.sass'
import ProductTile from './ProductTile/ProductTile'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, sortBy, uniq } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'
import promiseAllValues from '../../utils/promiseAllValues'
import defineProductPrice from './ProductTile/functions/defineProductPrice'

function Catalog({ subCategories, assignmentFilter }) {
  const { location, cart } = useContext(AuthContext)
  const [data, setData] = useState({ products: null, spots: null })
  const [displayedProducts, setDisplayedProducts] = useState(null)

  const nextStepPath = () => {
    const nextCategory = find(subCategories, ['status', 'notActive'])
    const currentCategory = find(subCategories, ['status', 'active'])
    if (nextCategory && nextCategory.pos > currentCategory.pos)
      return nextCategory.path
    return `/catalog/totals`
  }

  useEffect(() => {
    if (!isEmpty(subCategories)) {
      const activeCategory = find(subCategories, ['status', 'active'])
      if (activeCategory && activeCategory.categoryId) {
        promiseAllValues({
          products: getCollection({
            path: 'products',
            docIdName: 'productId',
            whereQueries: [
              {
                fieldPath: 'subCategory',
                op: '==',
                value: activeCategory.categoryId,
              },
            ],
          }),
          shops: getCollection({
            path: 'shops',
            docIdName: 'shopId',
          }),
        }).then((results) => {
          const spots = []
          const productsByLocation = results.products.filter((p) => {
            results.shops.forEach((s) => {
              s.spots.forEach((spot) => {
                if (spot.spotCity === location.id) {
                  spots.push(spot.spotId)
                }
              })
            })
            const productSpots = Object.keys(p.price)
            return productSpots.some((s) => spots.includes(s))
          })

          setData({ products: productsByLocation, spots: uniq(spots) })
          setDisplayedProducts(
            sortBy(
              productsByLocation.filter(
                (p) => p.assignment.includes(assignmentFilter) && true
              ),
              (p) =>
                defineProductPrice({
                  productData: p,
                  spotsByLocation: uniq(spots),
                })
            )
          )
        })
      }
    }
  }, [subCategories])

  useEffect(() => {
    if (!isEmpty(data.products)) {
      setDisplayedProducts(
        sortBy(
          data.products.filter(
            (p) => p.assignment.includes(assignmentFilter) && true
          ),
          (p) =>
            defineProductPrice({ productData: p, spotsByLocation: data.spots })
        )
      )
    }
  }, [assignmentFilter])

  return (
    <div className="Site-Content Catalog-Body">
      <div className="Catalog">
        {!displayedProducts || !data.spots ? (
          <div className="Spinner-Container">
            <Spinner type="button" theme="gray" />
          </div>
        ) : !isEmpty(displayedProducts) ? (
          displayedProducts.map((p) => (
            <ProductTile
              productCategory={
                find(subCategories, ['status', 'active'])
                  ? find(subCategories, ['status', 'active']).categoryId
                  : null
              }
              key={p.productId}
              {...p}
              spotsByLocation={data.spots}
              type={assignmentFilter}
            />
          ))
        ) : (
          <p className="Body-Regular-2 EmptyText">Немає товарів</p>
        )}
      </div>
      <div className="Catalog-Footer">
        {!isEmpty(displayedProducts) && (
          <p className="Caption-Regular TemplateText">
            Спорядження на зображеннях є прикладом
          </p>
        )}
        <div className="Catalog-Buttons">
          <Button
            title="Пропустити"
            theme="secondary"
            className="Skip"
            aClassName="Skip"
            type="navlink"
            path={nextStepPath()}
            disabled={
              nextStepPath() === '/catalog/totals' && isEmpty(cart.products)
                ? true
                : false
            }
          />
          <Button
            title="Далі"
            theme="primary"
            type="navlink"
            className="Continue"
            aClassName="Continue"
            path={nextStepPath()}
            disabled={
              nextStepPath() === '/catalog/totals' && isEmpty(cart.products)
                ? true
                : false
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Catalog
