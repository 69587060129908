import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import Button from '../../../../ui/Button/Button'
import Icon from '../../../../ui/Icon/Icon'
import { formatDateAsDayMonthYear } from '../../../../utils/date/dateFormat'
import getCollection from '../../../../utils/db/getCollection'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getFormattedValue from '../../../HomeSearch/DateChooser/functions/getFormattedValue'
import './OrdersList.sass'

function OrdersList({ orders }) {
  const [data, setData] = useState(null)
  const [parsedOrders, setParsedOrders] = useState([])

  useEffect(() => {
    promiseAllValues({
      shops: getCollection({ path: 'shops', docIdName: 'shopId' }),
      products: getCollection({ path: 'products', docIdName: 'productId' }),
      cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
      statuses: getCollection({
        path: 'config/orders/statuses',
        docIdName: 'statusId',
      }),
    }).then((result) => setData(result))
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      setParsedOrders(
        orders.map((o) => {
          const shop = find(data.shops, ['shopId', o.pickupShop])
          const spot = find(shop.spots, ['spotId', o.pickupSpot])
          const city = find(data.cities, ['cityId', spot.spotCity])
          const status = find(data.statuses, ['statusId', o.status])

          o.spotFullAddress = `${spot.title}, м. ${city.title}, ${spot.spotAddress}`
          o.city = city.title
          o.statusData = status
          return o
        })
      )
    }
  }, [orders, data])

  return (
    <div className="OrdersList">
      {parsedOrders.map((o) => (
        <OrderTile {...o} />
      ))}
    </div>
  )
}

function OrderTile({
  orderId,
  docId,
  created,
  city,
  statusData,
  dates,
  sameDay,
  spotFullAddress,
  price,
  ...o
}) {
  console.log('🚀 ~ file: OrdersList.jsx ~ line 52 ~ OrderTile ~ o', o)
  return (
    <div className="OrderTile">
      <h3>
        {city}{' '}
        <span
          className="OrderStatus"
          style={{ background: statusData.background, color: statusData.color }}
        >
          {statusData.title}
        </span>{' '}
      </h3>
      <p className="Caption-Regular OrderNumber">{orderId}</p>
      <div className="Order-Body">
        <div className="Body-Regular-2 Line">
          <Icon name="calendar-alt" />
          <span className="Data">
            {getFormattedValue({
              start: dates.start.seconds * 1000,
              end: dates.end.seconds * 1000,
              sameDay: sameDay,
            })}
          </span>
        </div>
        <div className="Body-Regular-2 Line">
          <Icon name="store" />
          <span className=" Data">{spotFullAddress}</span>
        </div>
        <div className="InfoLine">
          <p className="Caption-Regular">
            Дата оформлення:{' '}
            <span className="Caption-Medium">
              {formatDateAsDayMonthYear(created.seconds * 1000)}
            </span>
          </p>
          <p className="Caption-Regular">
            Всього: <span className="Body-Medium-1">{price} ₴</span>
          </p>
        </div>
        <Button
          title="Деталі замовлення"
          theme="secondary"
          className="OrderDetails-Button"
          size={32}
          type="navlink"
          path={`/profile/orders/${docId}`}
        />
      </div>
    </div>
  )
}

export default OrdersList
