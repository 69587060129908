import React, { useContext } from 'react'
import FondyPaymentForm from '../../components/FondyPaymentForm/FondyPaymentForm'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import { find, isEmpty } from 'lodash'
import addDoc from '../../utils/db/addDoc'
import setDoc from '../../utils/db/setDoc'
import { withRouter } from 'react-router-dom'
import FondyAutopaymentByToken from '../../components/FondyPaymentForm/FondyAutopaymentByToken'
import sendOllAppOrderSms from './functions/sendOllAppOrderSms'

function PaymentProcess({ ...router }) {
  const { user, cart } = useContext(AuthContext)

  const onPaymentSuccess = () => {
    const id = new Date().getTime().toString()
    const orderId = id.substring(id.length - 6)
    sendOllAppOrderSms({ orderId, sum: cart.price })
    addDoc({
      path: 'orders',
      data: {
        ...cart,
        orderId,
        status:
          cart.paymentType === 'full'
            ? 'mb0oZEnvz1eqUZ6FAY8m'
            : 'KGpVTDMZlJxyxn9zenpy',
        uid: user.uid,
        created: new Date(),
        updated: new Date(),
      },
    }).then(() =>
      setDoc({
        path: 'carts',
        docId: cart.id,
        data: {
          products: [],
          created: new Date(),
          updated: new Date(),
        },
      }).then(() => {
        if (cart.paymentType === 'half') {
          router.history.push(
            `/payment/success?part=true&sum=${cart.price - cart.priceToPay}`
          )
        } else {
          router.history.push('/payment/success')
        }
      })
    )
  }

  const onPaymentFailed = () => {
    router.history.push('/payment/failed')
  }

  const renderPaymentForm = () => {
    const userDefaultCard = find(user.cards, ['isDefault', true])
    if (userDefaultCard && userDefaultCard.recToken) {
      return (
        <FondyAutopaymentByToken
          amount={cart.priceToPay}
          uid={user.uid}
          onPaymentSuccess={onPaymentSuccess}
          onPaymentFailed={onPaymentFailed}
        />
      )
    } else {
      return (
        <>
          <div className="BackLine">
            <Button
              icon="angle-left"
              theme="internal-link"
              size={32}
              title="Повернутися"
              type="navlink"
              path="/checkout/payment-methods"
            />
          </div>
          <FondyPaymentForm
            uid={user.uid}
            email={user.email}
            orderDesc={'Оплата замовлення'}
            amount={cart.priceToPay}
            saveCard={cart.saveCard}
            onPaymentSuccess={onPaymentSuccess}
            onPaymentFailed={onPaymentFailed}
          />
        </>
      )
    }
  }

  return (
    <div className="SitePage">
      <div className="Site-Content PaymentProcess">
        {!isEmpty(user) && !isEmpty(cart) && renderPaymentForm()}
      </div>
    </div>
  )
}

export default withRouter(PaymentProcess)
