import React from 'react'
import './Home.sass'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import heroBg from './heroBg.jpg'
import HowItWorks from './HowItWorks'
import HomeSearch from '../../components/HomeSearch/HomeSearch'
import getCollection from '../../utils/db/getCollection'
// import getCollection from '../../utils/db/getCollection'
// import { createForm } from '../../utils/forms/createForm'
// import UserObject from '../../components/Auth/SignUp/functions/userObject'
// import getFormValues from '../../utils/forms/getFormValues'
// import { omit } from 'lodash'
// import setDoc from '../../utils/db/setDoc'

function Home() {
  //Update old users script
  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //   }).then((result) => {
  //     const newUsers = result
  //       .filter((u) => !u.version)
  //       .map((u) => {
  //         const form = createForm({
  //           formData: omit(u, ['avatar']),
  //           formPattern: new UserObject(),
  //         })
  //         return { userId: u.userId, ...getFormValues({ form }) }
  //       })

  //     Promise.all(
  //       newUsers.map((u) =>
  //         setDoc({ path: 'users', docId: u.userId, data: omit(u, ['userId']) })
  //       )
  //     )
  //       .then((result) => {
  //         console.log(
  //           '🚀 ~ file: Home.jsx ~ line 32 ~ Promise.all ~ result',
  //           result
  //         )
  //       })
  //       .catch((e) => {
  //         console.log('🚀 ~ file: Home.jsx ~ line 35 ~ Promise.all ~ e', e)
  //       })
  //   })
  // }

  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //     whereQueries: [{ fieldPath: 'phone', op: '==', value: 380631702147 }],
  //   }).then((result) => {
  //     console.log('🚀 ~ file: Home.jsx ~ line 56 ~ test ~ result', result)
  //   })
  // }

  return (
    <div className="Home">
      {/* <button onClick={test}>test</button> */}
      <div className="Page-Container">
        <div className="Hero" style={{ backgroundImage: `url(${heroBg})` }}>
          <Header />
          <HomeSearch />
          <div className="Home-Body">
            <HowItWorks />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home
