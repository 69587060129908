import React, { useState, useContext } from 'react'
import uniqid from 'uniqid'
import merchantConfig from '../../config/fondy'
import { functions } from '../../config/firebase'
import generateSignature from './generateSignature'
import Spinner from '../../ui/Spinner/Spinner'
import useScript from '../../hooks/useScript'
import './checkout-vue.css'
import { AuthContext } from '../../App'

function FondyAttachCardForm({ onPaymentSuccess, onPaymentFailed }) {
  const { user } = useContext(AuthContext)
  const [paymentToke, setPaymentToken] = useState(null)

  const orderId = uniqid('addCard-')
  const orderDesc = 'Прикріплення платіжної картки до профілю користувача'

  const Options = {
    options: {
      methods: ['card'],
      methods_disabled: ['wallets'],
      active_tab: 'card',
      fields: false,
      full_screen: false,
      button: true,
      email: true,
      theme: {
        preset: 'reset',
      },
    },
    css_variable: {
      main: '#5B0E8B',
    },
    params: {
      email: user.email,
      lang: 'uk',
    },
  }

  const loadFondyForm = () => {
    const payments = functions.httpsCallable('paymentsOnCall')

    const request = {
      merchant_id: merchantConfig.merchantId,
      order_id: orderId,
      order_desc: orderDesc,
      currency: merchantConfig.currency,
      amount: 0,
      server_callback_url:
        'https://europe-west3-ollapp-io.cloudfunctions.net/paymentsOnRequest?func=receivePaymentResponse',
      required_rectoken: 'Y',
      verification: 'Y',
      lang: 'uk',
      merchant_data: JSON.stringify({
        uid: user.uid,
        operation: 'attach-paymentcard',
      }),
    }

    payments({
      func: 'requestPaymentToken',
      data: {
        request: {
          ...request,
          signature: generateSignature(request),
        },
      },
    })
      .then(({ data }) => {
        const { response } = data
        if (response.response_status === 'success') {
          setPaymentToken(response.token)
          Options.params = { ...Options.params, token: response.token }
          const fondy = window.fondy('#fondy-form', Options)
          fondy
            .$on('success', () => onPaymentSuccess())
            .$on('error', () => onPaymentFailed())
        }
      })
      .catch((e) => {
        console.log('fondyPaymentForm getToken Error ->', e)
      })
  }

  useScript(
    'https://pay.fondy.eu/latest/checkout-vue/checkout.js',
    loadFondyForm
  )

  return (
    <div className="CardForm">
      {paymentToke ? (
        <>
          <h2 className="Title">Додати карту</h2>
          <div id="fondy-form"></div>
        </>
      ) : (
        <Spinner type="module" />
      )}
    </div>
  )
}

export default FondyAttachCardForm
