import React, { useState } from 'react'
import './PasswordResetForm.sass'
import PasswordStrengthBar from '../../../../components/Auth/SignUp/PasswordStrengthBar/PasswordStrengthBar'
import { createForm } from '../../../../utils/forms/createForm'
import FormRender from '../../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'
import Button from '../../../../ui/Button/Button'
import getFormValues from '../../../../utils/forms/getFormValues'
import isFormValid from '../../../../utils/forms/validation/isFormValid'
import { withRouter } from 'react-router'
import resetPasswordByRecoveryCode from '../functions/resetPasswordByRecoveryCode'
import { removeUrlQueryParams } from '../../../../utils/queries'

function PasswordResetForm({ onPasswordReset, ...router }) {
  const search = router.location.search
  const params = new URLSearchParams(search)
  const recoveryCode = params.get('passwordReset')

  const [form, setForm] = useState(
    createForm({
      formPattern: {
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            label: 'Пароль',
            placeholder: 'Створіть ваш пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    const values = getFormValues({ form })

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      resetPasswordByRecoveryCode({
        recoveryCode,
        newPassword: values.password,
      }).then(() => {
        router.history.push(
          removeUrlQueryParams({
            history: router.history,
            params: ['passwordReset'],
          })
        )
        onPasswordReset()
      })
    }
  }

  return (
    <form className="PasswordReset-Container" onSubmit={onSubmit}>
      <FormRender
        sections={[{ fields: ['password'] }]}
        form={form}
        setForm={setForm}
        errors={showErrors}
      />
      <PasswordStrengthBar password={form.password.value} />
      <Button
        title="Зберегти новий пароль"
        theme="primary"
        size={40}
        isLoading={isLoading}
        iconPosition="right"
        onClick={onSubmit}
        type="submit"
      />
    </form>
  )
}

export default withRouter(PasswordResetForm)
