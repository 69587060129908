import React, { useEffect } from 'react'
import Spinner from '../../ui/Spinner/Spinner'
import { functions } from '../../config/firebase'

function FondyAutopaymentByToken({
  amount,
  uid,
  onPaymentSuccess,
  onPaymentFailed,
}) {
  useEffect(() => {
    if (amount > 0) {
      const payments = functions.httpsCallable('paymentsOnCall')
      payments({
        func: 'requestPaymentWithToken',
        amount,
        uid,
      })
        .then(({ data }) => {
          if (data === 'success') onPaymentSuccess()
          else onPaymentFailed()
        })
        .catch((e) => {
          console.log('fondyPaymentForm getToken Error ->', e)
          onPaymentFailed()
        })
    } else {
      onPaymentSuccess()
    }
  }, [])

  return (
    <div className="Spinner-Container">
      <Spinner type="button" theme="gray" />
    </div>
  )
}

export default FondyAutopaymentByToken
