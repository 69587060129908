import React from 'react'
import SitePage from '../SitePage/SitePage'

function PrivacyPolicy() {
  return (
    <SitePage withFormData>
      <div className="Site-Content PageText-Container">
        <h2>Політика конфіденційності та захисту даних</h2>
        <p className="Body-Regular-2">Останнє оновлення: 27 жовтня 2020 року</p>
        <p>
          Відповідно до чинного законодавства щодо захисту даних Товариства з
          обмеженою відповідальністю «ОЛЛАПП Україна» (надалі — «ОЛЛАПП
          Україна») інформує Користувачів своєї інтернет-платформи про Політику
          конфіденційності та захисту даних, яка застосовується «ОЛЛАПП Україна»
          під час обробки персональних даних, добровільно наданих Користувачами,
          що зареєструвалися на Сайті ollapp.com.ua.
        </p>
        <p>
          «ОЛЛАПП Україна» приділяє велику увагу конфіденційності ваших даних.
          Скориставшись послугами, ви довірилися нам, і ми це цінуємо. Ми
          докладаємо всіх зусиль, щоб зберегти ваші особисті дані в безпеці. Ми
          діємо в інтересах наших клієнтів і не приховуємо інформації про
          обробку ваших персональних даних.
        </p>
        <p>
          Ця Політика конфіденційності поширюється на всі види інформації, яку
          ми збираємо через ці платформи або через інші канали, пов'язані з цими
          платформами (наприклад, за допомогою електронних повідомлень, що
          надходять в нашу службу підтримки клієнтів). Це робить читання не
          таким нудним.
        </p>
        <p>
          Ми можемо час від часу змінювати Політику конфіденційності.
          Рекомендуємо вам регулярно перевіряти цю сторінку, щоб бути в курсі
          останніх змін. Якщо ми внесемо такі зміни до Політики
          конфіденційності, які торкнуться безпосередньо вас (наприклад, якщо ми
          маємо намір обробляти ваші персональні дані в цілях, відмінних від
          тих, що були раніше вказані в цій Політиці конфіденційності), ми
          повідомимо вам про такі зміни до початку нової діяльності.
        </p>
        <p>
          Надаючи «ОЛЛАПП Україна» свої персональні дані за допомогою
          електронних форм, розміщених на Платформі, Користувачі в чіткій формі
          надають «ОЛЛАПП Україна» або будь-якій із дочірніх/афілійованих
          компаній «ОЛЛАПП Україна» свою згоду на обробку таких даних відповідно
          до умов Політики конфіденційності та захисту даних і для цілей,
          встановлених у цій Політиці.
        </p>
        <p>
          Користувачі повинні ознайомитися з цією Політикою конфіденційності та
          захисту даних до реєстрації на платформі «ОЛЛАПП Україна». Натискаючи
          кнопку «зареєструватися», Користувачі цим заявляють, що вони
          ознайомилися з Політикою конфіденційності та захисту даних і в чіткій
          формі погоджуються з нею.{' '}
        </p>
        <p>
          Під час реєстрації Користувачі повинні надати таку інформацію для
          створення або редагування свого облікового запису: ПІП користувача,
          адреса електронної пошти, номер телефону. Окрім цього, і за умови
          наявності дозволу від Користувачів, «ОЛЛАПП Україна» збирає дані,
          пов'язані з місцем перебування користувачів, включно з географічним
          розташуванням їхнього комп'ютера або мобільного пристрою в режимі
          реального часу.
        </p>
        <p>
          «ОЛЛАПП Україна» не отримує доступ до паспортних даних чи іншого
          документу, що посвідчує особу, якщо Користувач не порушив умови та
          правила користування Платформи. Даний доступ отримує підрядник «ОЛЛАПП
          Україна».
        </p>
        <p>
          Одразу після завершення процесу реєстрації всі Користувачі отримують
          доступ до свого профілю, який вони можуть заповнити та/або редагувати
          так, як вони вважають за потрібне. Інформація та дані, які надають
          Користувачі, міститимуться в їхньому обліковому записі й можуть бути
          змінені за допомогою функції «змінити профіль».
        </p>
        <p>
          Користувачі зобов'язані надавати правдиві та точні дані. Окрім цього,
          Користувачі несуть відповідальність за будь-які втрати або збитки (в
          тому числі упущену вигоду), які можуть бути завдані «ОЛЛАПП Україна»
          або будь-якій із її дочірніх/афілійованих компаній чи третім сторонам
          у результаті того, що надана інформація виявиться неправдивою,
          неточною, застарілою або недійсною.{' '}
        </p>
        <p>
          Дані, що їх збирає «ОЛЛАПП Україна» або будь-яка з її дочірніх/
          афілійованих компаній, використовуються лише для досягнення цілей,
          встановлених в Умовах використання Платформи, які «ОЛЛАПП Україна»
          може переглянути.{' '}
        </p>
        <p>
          • Законодавство, яке застосовується до персональних даних Користувачів
          (Захист персональних даних).
          <br />
          • Хто такий оператор персональних даних, що обробляє дані, які ви
          надаєте? <br />
          • Яку інформацію збирає «ОЛЛАПП Україна»? <br />
          • З якою метою збираються дані?
          <br />
          • Чи розголошує «ОЛЛАПП Україна» інформацію, яку збирає?
          <br />
          • Які права мають Користувачі?
          <br />
          • Як «ОЛЛАПП Україна» захищає дані, які надають Користувачі?
          <br />• Повідомлення та модифікації
        </p>
        <h3>
          Законодавство, яке застосовується до персональних даних Користувачів
          (Захист персональних даних)
        </h3>
        <p>
          «ОЛЛАПП Україна» бере на себе зобов'язання завжди дотримуватися
          чинного законодавства щодо захисту персональних даних. Користувачам
          повідомляють, що персональні дані, які вони надають Платформі,
          підлягають автоматичній обробці та стають частиною файлів, які
          належать «ОЛЛАПП Україна», що виступатиме в ролі оператора
          персональних даних для цілей управління Користувачами, які
          зареєструвалися на Платформі «ОЛЛАПП Україна».
        </p>
        <p className="Body-Bold-2">
          Основна інформація щодо захисту персональних даних:
        </p>
        <table>
          <tr>
            <td>Найменування</td>
            <td>«ОЛЛАПП Україна»</td>
          </tr>
          <tr>
            <td>Мета</td>
            <td>
              Управління й надання запитуваних послуг. Забезпечення безпеки та
              відповідного середовища для безпечного надання послуг.
              Відправлення оновлень та маркетингової інформації Користувачам.
            </td>
          </tr>
          <tr>
            <td>Легітимізація</td>
            <td>
              Дотримання договірних відносин, законні інтереси та згода
              Користувача.
            </td>
          </tr>
          <tr>
            <td>Права</td>
            <td>
              Право на доступ, виправлення та видалення даних, а також інші
              права, про які повідомляється в додатковій інформації.
            </td>
          </tr>
          <tr>
            <td>Додаткова інформація</td>
            <td>
              Додаткова докладна інформація щодо захисту даних міститься в
              наступних розділах.
            </td>
          </tr>
        </table>
        <p>
          Відповідно до чинного законодавства ми створили Політику
          конфіденційності, яку «ОЛЛАПП Україна» використовує під час обробки
          даних Користувачів, що зареєструвалися на Платформі.
        </p>
        <h3>
          Хто такий оператор персональних даних, що обробляє дані, які ви
          надаєте?
        </h3>
        <p>
          Уся персональна інформація, яка надається або збирається через «ОЛЛАПП
          Україна», обробляється «ОЛЛАПП Україна», «Fondy», що виступає в ролі
          оператора персональних даних. Контактна інформація «ОЛЛАПП Україна»
          міститься нижче.
        </p>
        <p className="Body-Bold-2">
          Оператор персональних даних для ваших даних:
        </p>
        <table>
          <tr>
            <td>Найменування</td>
            <td>«ОЛЛАПП Україна»</td>
          </tr>
          <tr>
            <td>Поштова адреса</td>
            <td>«вулиця Чоловського, 5, місто Львів,Україна, 79018»</td>
          </tr>
          <tr>
            <td>Телефон</td>
            <td>«+38(067)796-17-17»</td>
          </tr>
          <tr>
            <td>Форма для зворотного зв'язку</td>
            <td>«support@ollapp.com.ua»</td>
          </tr>
        </table>
        <h3>Яку інформацію збирає «ОЛЛАПП Україна»?</h3>
        <p>
          Платформа «ОЛЛАПП Україна» (яка включає веб-формат) використовується
          Користувачами й Постачальниками. Інформація, яку ми отримуємо від
          Користувачів, збирається відповідно до нижчевикладених положень.
        </p>
        <p>
          «ОЛЛАПП Україна» збирає інформацію безпосередньо в Користувачів, а
          також інформацію, яка надається в непрямий спосіб через використання
          Платформи «ОЛЛАПП Україна».
        </p>
        <p>
          - Реєстраційні дані: інформація, яку надають Користувачі під час
          створення облікового запису на Платформі «ОЛЛАПП Україна»: ПІП
          користувача, адреса електронної пошти, номер телефону.
        </p>
        <p className="Body-Bold-2">
          «ОЛЛАПП Україна» не отримує доступ до паспортних даних чи іншого
          документу, що посвідчує особу, якщо Користувач не порушив умови та
          правила користування Платформи. Даний доступ отримує підрядник «ОЛЛАПП
          Україна».
        </p>
        <p>
          - Інформація профілю Користувача: інформація, яку додають Користувачі
          на Платформі для того, щоб мати змогу використовувати послуги «ОЛЛАПП
          Україна», тобто номер мобільного телефону. Користувачі можуть
          переглядати та редагувати свої персональні дані, коли забажають.
          «ОЛЛАПП Україна» не зберігає реквізити кредитних карт Користувачів,
          але такі реквізити передаються ліцензійним операторам електронних
          платежів, які отримують дані та зберігають їх із метою полегшення
          процесу здійснення платежу Користувачам і управління такими даними від
          імені «ОЛЛАПП Україна». Користувачі можуть надати таким постачальникам
          запит на надання їм їхньої політики конфіденційності в будь-який час.
        </p>
        <p>
          «ОЛЛАПП Україна» за жодних обставин не зберігає такі дані на своїх
          серверах.{' '}
        </p>
        <p>
          - Додаткова інформація, яку Користувачі зобов’язуються надати: зріст,
          вага, розмір ноги та інша інформація для надання належного сервісу
          Користувачам, а також будь-яка інформація, яку Користувач може надати
          «ОЛЛАПП Україна» для інших цілей. Приклади включають фотографію
          Користувача.
        </p>
        <p>
          - Інформація про зв'язок із «ОЛЛАПП Україна»: «ОЛЛАПП Україна» має
          доступ до інформації, що її надають Користувачі для вирішення
          будь-яких питань або скарг щодо використання Платформи за допомогою
          Форми для зворотного зв'язку, електронною поштою або телефоном через
          службу підтримки клієнтів.
        </p>
        <p>
          - Дані, отримані в процесі використання Платформи: «ОЛЛАПП Україна»
          збирає дані, які створюються кожного разу, коли Користувачі
          взаємодіють із Платформою.
        </p>
        <p>
          - Дані платформи та пристрою: «ОЛЛАПП Україна» зберігає дані на
          пристрої та на платформі, що їх використовують Користувачі для доступу
          до послуг. Такі дані — це:
          <br />
          • IP-адреса, яку використовує кожен Користувач для підключення до
          інтернету за допомогою свого комп'ютера або мобільного телефону.
          <br />
          • Інформація про комп'ютер або мобільний телефон Користувача, як-от
          спосіб підключення до інтернету, тип браузера, версія операційної
          системи, тип пристрою.
          <br />
          • Повні URL-адреси історії відвідувань, включно з датою та часом.
          <br />
          • Дані облікового запису Користувача: інформація про замовлення,
          зроблені кожним Користувачем, а також зворотний зв'язок та/або
          коментарі кожного Користувача щодо замовлення.
          <br />• Історія відвідування сайтів браузера та вподобання.
        </p>
        <p>
          - Дані щодо того, звідки користувач заходить на Платформу: якщо
          Користувач заходить на Платформу «ОЛЛАПП Україна» із зовнішнього
          джерела (як-от посилання з іншого веб-сайту або соціальна мережа),
          «ОЛЛАПП Україна» збирає дані про таке джерело.
        </p>
        <p>
          - Дані, отримані в результаті управління інцидентами: якщо Користувач
          звертається до Платформи «ОЛЛАПП Україна» за допомогою Форми (в
          довільній формі) для зворотного зв'язку або за номером телефону
          «ОЛЛАПП Україна», «ОЛЛАПП Україна» збирає повідомлення, отримані у
          форматі, який використовує Користувач, і може збирати та зберігати їх
          для того, щоб управляти наявними або майбутніми інцидентами.
        </p>
        <p>
          - Дані, отримані з файлів «cookie»: «ОЛЛАПП Україна» використовує свої
          власні файли cookie та файли cookie третьої сторони, щоб полегшити
          Користувачам переглядати сторінки веб-сайту, а також для цілей
          статистики (див. Політику щодо файлів cookie).
        </p>
        <p>
          - Дані, отримані від третіх сторін: «ОЛЛАПП Україна» може збирати
          персональні дані або інформацію від третіх сторін лише у випадках,
          коли Користувач надав дозвіл таким третім сторонам розголошувати таку
          інформацію «ОЛЛАПП Україна». Наприклад, якщо Користувач створює
          обліковий запис через свій обліковий запис у Facebook, Facebook може
          розголошувати нам дані такого Користувача, які можуть міститися в
          його/її профілі Facebook (як-от ім'я, стать або вік). Якщо Користувач
          отримує доступ до «ОЛЛАПП Україна» через продукти та послуги, які
          пропонує компанія Google, Google може надсилати «ОЛЛАПП Україна» дані
          щодо перегляду Користувачем сторінок через посилання, створені Google.
          Користувач може контролювати інформацію, що її надає третя сторона,
          відповідно до політики конфіденційності такої третьої сторони.
        </p>
        <p>
          - Дані геолокації: за умови наявності дозволу Користувачів, «ОЛЛАПП
          Україна» збиратиме дані щодо їхнього місця перебування, включно з
          географічним місцем перебування їхнього комп'ютера або мобільного
          пристрою в режимі реального часу. «ОЛЛАПП Україна» потрібно обробляти
          ці дані для виконання встановлених договірних зобов'язань. Якщо
          Користувач не надає дані, запитувані послуги можуть бути недоступні, і
          «ОЛЛАПП Україна» не зможе їх надавати.
        </p>
        <h3>З якою метою збираються дані?</h3>
        <p className="Body-Bold-2">
          1. Для того, щоб використовувати Платформу «ОЛЛАПП Україна».
        </p>
        <p>
          1.1. «ОЛЛАПП Україна» використовує дані, отримані від Користувачів для
          того, щоб надати їм доступ до й можливість взаємодіяти з Платформою
          «ОЛЛАПП Україна», а також надавати послуги, що їх запитують
          Користувачі через свій обліковий запис на Платформі «ОЛЛАПП Україна»
          відповідно до процедури, описаної в Умовах використання. Під час
          виконання замовлення дані можуть бути використані:
          <br />
          • Установою або компанією, яка продає продукт, якщо Користувач доручив
          придбати продукт.
          <br />
          • Службою обслуговування клієнтів, з якою «ОЛЛАПП Україна» уклала
          договір, з метою попередження Користувача про будь-які можливі
          інциденти або опитування Користувача щодо того, чому він залишив
          негативний відгук про надану послугу. «ОЛЛАПП Україна» може
          використовувати дані, надані в замовленні, для управління будь-якими
          інцидентами, що можуть виникнути під час надання послуг.
          <br />
          • Вони також можуть бути використані для того, щоб продемонструвати
          Користувачам усі замовлення, зроблені через Платформу, і надати їм
          можливість висловитися щодо послуг, наданих «ОЛЛАПП Україна».
          <br />• Платіжною платформою (ліцензійний оператор електронних
          платежів) для виставлення рахунків Користувачам.
        </p>

        <p>
          1.2. «ОЛЛАПП Україна» може використовувати персональні дані, що їх
          надають Користувачі, для спілкування з Користувачами через електронну
          пошту або за допомогою SMS-повідомлень щодо діяльності служби. «ОЛЛАПП
          Україна» може надсилати (за вибором «ОЛЛАПП Україна») повідомлення на
          мобільний телефон Користувача з інформацією щодо статусу замовлення.
          Коли замовлення виконане, «ОЛЛАПП Україна» може надсилати (за вибором
          «ОЛЛАПП Україна» ) відомість/квитанцію, яка містить ціну, на
          електронну адресу Користувача. Під час реєстрації Користувача на
          Платформі вважається, що він/ вона погоджуються з Політикою
          конфіденційності та уповноважують «ОЛЛАПП Україна» надсилати такі
          повідомлення.
        </p>
        <p>
          1.3. «ОЛЛАПП Україна» також використовує інформацію для проведення
          дослідження та аналізу з метою вдосконалення послуг, надаваних
          Користувачам, а також із метою розробки та вдосконалення характеристик
          послуг, що їх надає «ОЛЛАПП Україна». «ОЛЛАПП Україна» використовує
          інформацію для внутрішніх цілей статистики для того, щоб аналізувати
          поведінку Користувачів, зрозуміти, як Користувачі використовують
          Платформу «ОЛЛАПП Україна», і вдосконалити пропоновані послуги,
          включно з можливістю додавати нові, різноманітні послуги на Платформу.{' '}
        </p>

        <p>
          Обробка даних, описаних вище, є необхідною для виконання договору між
          Користувачами та «ОЛЛАПП Україна», який укладається шляхом прийняття
          Умов використання під час реєстрації на Платформі «ОЛЛАПП Україна».
          Виконання вищезгаданого договору є основою, яка легітимізує обробку
          даних, що її здійснює «ОЛЛАПП Україна» або треті сторони з метою
          надання послуг, запитуваних Користувачами через Платформу.
        </p>
        <p className="Body-Bold-2">
          2. Для забезпечення безпеки та належного середовища для надійного
          надання послуг.
        </p>
        <p>
          2.1. «ОЛЛАПП Україна» також може використовувати інформацію
          Користувачів для ідентифікації й розслідування шахрайства та іншої
          протиправної діяльності, а також порушень Умов використання. Для цього
          «ОЛЛАПП Україна» може розголошувати дані Користувачів платформам, які
          займаються аналізом шахрайської діяльності.
        </p>

        <p>
          «ОЛЛАПП Україна» може використовувати надану Користувачами інформацію
          для обробки даних, як описано вище, відповідно до законних інтересів
          щодо захисту та належного використання Платформи та чинного
          законодавства, а також для забезпечення належного та безпечного
          надання послуг.
        </p>
        <p className="Body-Bold-2">
          3. Рекламні та комерційні пропозиції (онлайн та офлайн)
        </p>
        <p>
          «ОЛЛАПП Україна» використовує технології третіх сторін, інтегровані в
          Платформу, з метою збору даних Користувачів та їхніх уподобань і
          використовує їх разом із системами управління відносинами з клієнтами
          та передовими технологіями на користь Користувачів. Обробка даних
          відбувається на основі зібраної інформації:
        </p>

        <p>
          3.1. «ОЛЛАПП Україна» може надсилати Користувачам на їхню електронну
          адресу рекламні повідомлення та/або пропозиції, пов'язані з послугами,
          що їх пропонує «ОЛЛАПП Україна», які можуть бути цікавими для
          Користувачів. «ОЛЛАПП Україна» може оцінювати та персоналізувати такі
          рекламні повідомлення відповідно до вподобань Користувачів. Якщо
          Користувач «ОЛЛАПП Україна» не бажає отримувати інформацію та/або
          рекламні оголошення, він/вона може в будь-який час відмовитися від
          підписки (така можливість надається в електронному листі). У такому
          випадку «ОЛЛАПП Україна» негайно припинить надсилати вищезгадану
          інформацію.{' '}
        </p>

        <p>
          3.2. «ОЛЛАПП Україна» може надсилати Користувачам повідомлення та/або
          пропозиції, пов'язані з послугами, на мобільні телефони, які містять
          рекламну інформацію та/або пропозиції. Якщо Користувач «ОЛЛАПП
          Україна» не бажає отримувати рекламні повідомлення, описані в цьому
          розділі.{' '}
        </p>

        <p>
          3.3. Використовуючи Платформу «ОЛЛАПП Україна», Користувачі можуть
          отримувати комерційні повідомлення від третіх сторін, пов'язаних із
          Платформою, як-от Facebook і Google, відповідно до налаштувань
          конфіденційності, встановлених кожним Користувачем на Платформі.
        </p>

        <p>
          Обробка даних, описана вище, відбувається відповідно до законних
          інтересів «ОЛЛАПП Україна» під час здійснення діяльності у сфері
          маркетингу на користь Користувачів, про що йдеться в наступному
          розділі. Слід зазначити, що Користувачі можуть направити запит за
          довільною формою для відмови від маркетингових онлайн-послуг або для
          того, щоб закрити свій обліковий запис, якщо вони не бажають
          отримувати зразки разом із замовленнями «ОЛЛАПП Україна».{' '}
        </p>
        <h3>Чи розголошує «ОЛЛАПП Україна» інформацію, яку збирає?</h3>
        <p>
          Як зазначено в попередньому розділі, персональні дані, що їх збирає
          «ОЛЛАПП Україна», використовуватимуться лише для досягнення мети,
          встановленої в Умовах використання.{' '}
        </p>

        <p>
          Для виконання договірних зобов'язань, бездоганного виконання послуг, а
          також для своїх власних законних інтересів, «ОЛЛАПП Україна»
          розголошує певні персональні дані Користувачів:
        </p>

        <p>
          - Постачальникам послуг: Сторонні постачальники послуг «ОЛЛАПП
          Україна», які здійснюють розрахунки, верифікації, виконують замовлення
          та/ або вирішують інциденти, отримують доступ до персональних даних
          Користувачів, які їм необхідні для виконання їхніх обов'язків, але не
          можуть використовувати їх для будь-яких інших цілей. Вони повинні
          обробляти вищезазначені персональні дані відповідно до Політики
          конфіденційності та чинного законодавства про захист даних.
        </p>

        <p>
          - Соціальним мережам, що їх використовує Користувач для використання
          Платформи. Якщо Користувач прив'язує свій обліковий запис «ОЛЛАПП
          Україна» до іншої соціальної мережі або платформи третьої сторони,
          «ОЛЛАПП Україна» може використовувати інформацію, яка надається такій
          соціальній мережі або третій стороні, за умови, що така інформація
          надається «ОЛЛАПП Україна» відповідно до політики конфіденційності
          соціальної мережі або платформи третьої сторони.{' '}
        </p>

        <p>
          - Постачальники, з якими «ОЛЛАПП Україна» уклала комерційні договори,
          можуть використовувати через веб-сайт для полегшення процесу виконання
          умов, з якими «ОЛЛАПП Україна» уклав комерційні договори. У таких
          випадках ПІП, адреса електронної пошти, номер телефону, паспортні дані
          чи інший документ, що посвідчує особу (у виключних випадках їх фото чи
          сканокопію), Користувача можуть розголошуватися відповідному
          контрагенту з метою полегшення транзакції. Якщо Користувач
          безпосередньо зв'язується з вищезгаданими постачальниками й надає їм
          свої дані, «ОЛЛАПП Україна» не несе відповідальності за те, як такі
          постачальники використовуватимуть такі дані.{' '}
        </p>

        <p>
          - Користувачі можуть отримувати електронні листи від «ОЛЛАПП Україна»
          та/або контрагента для підтвердження замовлення, організації
          повернення, отримання відгуку від Користувача щодо замовлення або
          короткого опису щодо замовлення. За жодних обставин контрагенти не
          отримують і не мають права запитувати реквізити кредитних карт
          Користувачів. Дія цієї Політики конфіденційності не розповсюджується
          на надану Користувачами контрагентів інформацію, яка перебуває за
          межами контролю «ОЛЛАПП Україна».{' '}
        </p>

        <p>
          - Якщо у «ОЛЛАПП Україна» змінюються власники або більшу частину її
          активів купує третя сторона, Користувачам повідомляється, що «ОЛЛАПП
          Україна» передає їхні дані організації-покупцю для того, щоб
          продовжувати надавати послуги відповідно до оброблених даних. Новий
          оператор обробки даних повідомляє Користувачів про їхні
          ідентифікаційні дані. «ОЛЛАПП Україна» заявляє, що вона буде
          виконувати своє зобов'язання щодо інформування наглядового органа в
          разі настання таких обставин, а також про зміну оператора обробки
          даних, якщо й коли це станеться. Обробка даних проводитиметься
          відповідно до договору, укладеного з «ОЛЛАПП Україна».{' '}
        </p>

        <p>
          - «ОЛЛАПП Україна» гарантує, що всі комерційні партнери, спеціалісти
          технічного профілю, постачальники або незалежні треті сторони
          зобов'язані за договором обробляти інформацію, яка їм надається,
          відповідно до інструкцій «ОЛЛАПП Україна», цієї Політики
          конфіденційності й чинного законодавства про захист даних.{' '}
        </p>

        <p>
          - Службам безпеки та правоохоронним органам: «ОЛЛАПП Україна» може
          розголошувати персональні дані облікових записів Користувачів, якщо
          вважає, що таке розголошення є необхідним для дотримання
          законодавства, для того, щоб примусити виконувати або застосовувати
          Умови використання або захистити права, майно та безпеку «ОЛЛАПП
          Україна», її Користувачів або третіх сторін. Вищевикладене включає
          обмін інформацією з іншими компаніями та організаціями, а також із
          правоохоронними органами для захисту від шахрайства та зменшення
          кредитних ризиків.{' '}
        </p>

        <p>
          - Операторам платежів: «ОЛЛАПП Україна» не зберігає реквізити
          банківських карт Користувачів. Проте, реквізити кредитних/дебетових
          карт Користувачів (номер і дата закінчення терміну дії) зберігаються
          операторами платежів, обраними «ОЛЛАПП Україна», які мають засоби
          безпеки високого рівня, оскільки дотримуються стандарту безпеки даних
          індустрії платіжних карт.{' '}
        </p>

        <p>
          - «ОЛЛАПП Україна» може розголошувати інформацію органам виконавчої
          влади та/або третім сторонам, якщо це вимагається відповідно до
          законодавства у зв'язку з розслідуванням кримінальних справ та
          неправомірною діяльністю.{' '}
        </p>

        <p>
          - Компаніям групи «ОЛЛАПП Україна»: Для того, щоб мати можливість
          надавати послуги, «ОЛЛАПП Україна» може передавати певні персональні
          дані Користувачів своїм дочірнім компаніям, залежно від географічної
          зони, з якої Користувачі замовляють послуги. Користувачам цим
          повідомляється, що коли вони реєструються на Платформі в будь-якій
          країні, де «ОЛЛАПП Україна» здійснює свою діяльність, їхні дані
          зберігатимуться в базі даних «ОЛЛАПП Україна», яка належить
          українській компанії «ОЛЛАПП Україна».{' '}
        </p>
        <p>
          - Телефонним інформаційним центрам та службам управління інцидентами:
          Для того, щоб служба обслуговування клієнтів та телефонний
          інформаційний центр мали змогу оцінити рівень задоволення Користувачів
          та для надання послуг адміністративної підтримки, «ОЛЛАПП Україна»
          може розголошувати дані Користувачів компаніям, за умови, що «ОЛЛАПП
          Україна» має право це робити й дотримується вимог безпеки, викладених
          у попередньому розділі.{' '}
        </p>

        <p>- Службам, які здійснюють контроль за спробами шахрайства.</p>

        <p>
          «ОЛЛАПП Україна» розголошує дані Користувачів службам, які здійснюють
          контроль за спробами шахрайства, для оцінки ризиків під час здійснення
          транзакції.
        </p>

        <p>
          - Постачальникам послуг з анонімізації деяких даних для того, щоб
          запобігти неправомірному використанню даних Користувачів сторонніми
          постачальниками послуг. «ОЛЛАПП Україна» може розголошувати дані
          Користувачів для того, щоб їх можна було анонімізувати й
          використовувати виключно для надання послуг Користувачам. Наприклад,
          «ОЛЛАПП Україна» може надавати третім сторонам номери телефонів
          Користувачів для їхньої анонімізації й надання в такому форматі
          постачальникам, які виконують послуги, замовлені Користувачами.
        </p>

        <p>
          - Користувачі «ОЛЛАПП Україна» в чіткій формі погоджуються надати
          дочірнім/афілійованим компаніям групи «ОЛЛАПП Україна» згоду на доступ
          до їхніх персональних даних із будь-якої території для надання послуг,
          запитуваних Користувачами.
        </p>

        <p>
          - Дані Користувачів «ОЛЛАПП Україна» не розголошуються жодній третій
          стороні, за винятком таких випадків: це є необхідним для надання
          запитуваних послуг, якщо «ОЛЛАПП Україна» співпрацює з третіми
          сторонами; «ОЛЛАПП Україна» має чітку та недвозначну згоду
          Користувача; компетентний орган влади вимагає розголошення інформації
          відповідно до своїх обов'язків (для проведення розслідування, для
          запобігання неправомірним діям); або відповідно до чинного
          законодавства.
        </p>

        <p>
          - За згодою Користувача: окрім вищевикладеного, Користувачам
          повідомляється в разі, якщо будь-які їхні дані мають бути розголошені
          комерційним партнерам Платформи та/або веб-сайту не для цілей,
          встановлених в Умовах використання «ОЛЛАПП Україна», для того, щоб
          надати Користувачам можливість прийняти рішення, надавати дозвіл на
          розголошення інформації, чи не надавати.
        </p>

        <p>
          - Також за згодою Користувача: третім сторонам, які не є комерційними
          партнерами й не пов'язані з «ОЛЛАПП Україна», за умови, що Користувач
          надав попередню, недвозначну згоду на розголошення своїх персональних
          даних.
        </p>

        <p>
          У жодному разі розголошення даних не може здійснюватися з метою
          обміну, продажу або надання в оренду персональних даних Користувачів у
          комерційних цілях або всупереч зобов'язанням, встановленим у цій
          Політиці конфіденційності.
        </p>
        <h3>Які права мають Користувачі?</h3>
        <div className="Body-Bold-2">
          - Як довго ми зберігаємо ваші персональні дані?
        </div>
        <p>
          За винятком випадків, коли суб'єкт даних вимагає видалення своїх
          персональних даних, такі дані зберігаються без обмеження їх строку
          зберігання.
        </p>

        <p>
          Якщо Користувач відкликає свою згоду на обробку, дані блокуються, а
          їхня обробка припиняється. Дані зберігаються безстроково для того, щоб
          «ОЛЛАПП Україна» могла висунути претензію або захистити себе від
          будь-яких претензій.{' '}
        </p>
        <p className="Body-Bold-2">- Право на відкликання згоди</p>
        <p>
          Користувачі можуть відкликати свою згоду на обробку їхніх даних
          «ОЛЛАПП Україна» в будь-який час. Для цього їм необхідно заповнити та
          надіслати Форму (в довільному форматі) для зворотного зв'язку.
          Суб'єкти даних можуть відкликати свою згоду в будь-який час.
          Користувачі погоджуються з тим, що відкликання згоди може призвести до
          того, що «ОЛЛАПП Україна» не зможе надавати їм послуги на своїй
          Платформі.{' '}
        </p>
        <p className="Body-Bold-2">
          - До якої інформації Користувач може отримати доступ?{' '}
        </p>
        <p>
          «ОЛЛАПП Україна» гарантує Користувачам доступ до великої кількості
          інформації, пов'язаної з їхнім обліковим записом Користувачі можуть
          переглядати таку інформацію та, у деяких випадках, оновлювати її.
        </p>
        <p>
          Усі Користувачі можуть отримати доступ до свого профілю, можливість
          заповнювати та/або редагувати його, якщо вони вважають це за потрібне.
          Інформація та дані, які надають Користувачі, є завжди доступними в
          обліковому записі Користувача й можуть змінюватися ними за допомогою
          функції «змінити профіль».
        </p>
        <p className="Body-Bold-2">- Які можливості мають Користувачі? </p>
        <p>
          Як зазначено вище, якщо Користувач не бажає більше отримувати
          електронні повідомлення від «ОЛЛАПП Україна», він може змінити
          параметри підписки, натиснувши на кнопку «Відписатись».{' '}
        </p>
        <p>
          Окрім цього, Користувачі можуть не надавати «ОЛЛАПП Україна» дані
          геолокації. Більшість мобільних пристроїв надають Користувачам
          можливість вимкнення служби визначення місця перебування. Цю
          можливість зазвичай можна знайти в меню налаштувань пристрою. Якщо у
          вас виникають будь-які сумніви щодо вимкнення служби визначення місця
          перебування на вашому пристрої, «ОЛЛАПП Україна» рекомендує вам
          зв'язатися з виробником вашого пристрою або оператором послуги.
        </p>
        <p>
          Окрім описаних вище прав, Користувачі мають право надавати претензію
          на розгляд у наглядовий орган.
        </p>
        <h3>Як ми захищаємо дані Користувачів?</h3>
        <p>
          Відповідно до характеру оброблюваних персональних даних і обставин
          обробки «ОЛЛАПП Україна» вживає необхідні кроки для того, щоб уникнути
          (наскільки це можливо й завжди відповідно до рівня розвитку
          технологій) зміни, втрати, несанкціонованого доступу до або обробки
          даних. Як зазначено вище, надавані персональні дані не розголошуються
          третім особам без попереднього дозволу суб'єкта даних.
        </p>
        <h3>Повідомлення та модифікації </h3>
        <p>
          Як зазначено вище, усі Користувачі мають право на доступ, оновлення та
          видалення своїх даних, а також право заперечувати проти обробки даних.
          Ви можете здійснювати свої права й робити будь-які запити щодо
          Політики конфіденційності «ОЛЛАПП Україна» за допомогою Форми для
          зворотного зв’язку (в довільному форматі).
        </p>
        <p>
          У зв'язку з тим, що «ОЛЛАПП Україна» постійно розвивається, ця
          Політика конфіденційності та Умови використання можуть змінюватися.
          «ОЛЛАПП Україна» надсилає Користувачам повідомлення в разі внесення
          суттєвих змін і модифікацій до цих документів електронною поштою або
          за допомогою іншого способу, який забезпечує їхнє отримання
          Користувачами. «ОЛЛАПП Україна» в жодному разі не змінюватиме свою
          Політику, якщо це може призвести до зниження ефективності захисту
          персональних даних наших Користувачів.
        </p>
        <h2>Політика щодо файлів «cookie»</h2>
        <p className="Body-Regular-2">Останнє оновлення: 27 жовтня 2020 року</p>
        <p>
          «ОЛЛАПП Україна» (надалі — «ОЛЛАПП Україна») інформує вас щодо
          використання файлів «cookie» на своїй онлайн-платформі.{' '}
        </p>
        <h3>Що таке файли «cookie»? </h3>
        <p>
          Файли «cookie» — це невеликі за розміром файли, які надсилаються
          вебсайтами в браузер і зберігаються в терміналі користувача (це може
          бути комп'ютер, мобільний пристрій, планшет або будь-який інший
          пристрій). Вони грають важливу роль у постачанні послуг інформаційного
          суспільства. Окрім цього, вони надають веб-сайтам можливість зберігати
          та отримувати інформацію про користувачів або їхнє обладнання, сайти,
          які користувачі відвідують та, залежно від отриманої інформації,
          можуть використовуватися для ідентифікації користувача та
          вдосконалення запропонованої послуги.
        </p>
        <h3>Які є типи файлів cookie? </h3>
        <p>
          Різні типи використовуваних файлів cookie можна класифікувати на
          основі низки критеріїв: час активності, керівна структура та мета.
          Виходячи з тривалості активності користувача в браузері, файли cookie
          поділяються на Сеансові файли cookie та Постійні файли cookie.
          Сеансові фалові cookie використовуються, коли користувач переглядає
          сторінку в браузері, та зберігаються лише впродовж сеансу зв'язку.
          Вони зберігають інформацію щодо того, як відбувається доступ до
          сторінок і як вони використовуються. Постійні файли cookie — це файли
          cookie, які зберігаються в браузері користувача впродовж обмеженого
          періоду часу, навіть після завершення перегляду сторінок у браузері.
        </p>

        <p>
          Залежно від органа, який управляє та створює файли cookie, файли
          cookie поділяються на Власні файли cookie та Файли cookie третьої
          сторони. Власні файли cookie створюються доменом веб-сайту, який
          відвідують користувачі, і до яких доступ має лише домен. Зазвичай вони
          використовуються для запам'ятовування користувача під час кожного його
          відвідування веб-сайту. Файли Cookie третьої сторони створюються й
          управляються доменами третіх сторін, а не веб-сайтів, які відвідує
          користувач. Зазвичай вони використовуються для відстеження
          користувачів на різних веб-сайтах, щоб надсилати користувачам
          індивідуалізовану рекламу.
        </p>

        <p>
          Останній тип класифікації ґрунтується на меті файлів cookie: Технічні
          файли cookie, Аналітичні файли cookie та Індивідуальні файли cookie.
          Технічні файли cookie — це файли, які вважаються необхідними для
          належного функціонування веб-сайту та послуг, які такий веб-сайт
          пропонує. Іноді вони використовуються для запам'ятовування
          користувачів під час того, як вони передивляються сторінки в
          інтернеті, і для запам'ятовування продукції, яку користувачі обирають
          і згодом оплачують. Аналітичні файли cookie використовуються для
          анонімного вивчення поведінки користувачів під час того, як вони
          переглядають сторінки в інтернеті. Деяка інформація, яка збирається
          цим типом файлів cookie, включає інформацію про кількість відвідувачів
          сайту та інформацію про продукти, які приваблюють користувачів на
          цьому сайті. Індивідуалізовані файли cookie надають веб-сайту
          можливість використовувати набір попередньо визначених характеристик,
          як-от мова або тип використовуваного браузера, для кожного
          користувача.
        </p>

        <p>
          «ОЛЛАПП Україна» використовує файли cookie для отримання більшої
          кількості інформації про споживачів, які відвідують наш веб-сайт, з
          метою адаптування сайту під їхні фактичні потреби та інтереси. Робота,
          яку проводить «ОЛЛАПП Україна» разом із третіми сторонами для
          покращення збирання даних за допомогою таких інструментів, не надає
          права таким третім особам використовувати зібрану інформацію, за
          винятком випадків, коли мета такого використання — вдосконалення
          сторінки та послуг, що їх пропонує «ОЛЛАПП Україна».
        </p>
        <h3>Чому ми використовуємо файли cookie? </h3>
        <p>
          важливими для надання користувачам можливості вільно переглядати
          Платформу «ОЛЛАПП Україна». Такі файли cookie використовуються, щоб
          допомогти вдосконалити надавані користувачам послуги шляхом
          вимірювання використовування та продуктивності сайту з метою його
          оптимізації й налаштування під потреби користувачів.
        </p>

        <p>
          «ОЛЛАПП Україна» також використовує файли Cookie для інших цілей,
          як-от:
          <br />
          • Зрозуміти поведінку Користувачів та проаналізувати інформацію для
          цілей статистики.
          <br />
          • Вдосконалити безпеку та запобігти шахрайським діям.
          <br />
          • Покращити швидкість Платформи.
          <br />
          • Зберегти налаштування, пов'язані з мовою й містами, з яких
          Користувачі заходять на Платформу.
          <br />• Вдосконалити досвід використання Платформи Користувачами.
        </p>

        <p>
          Файли cookie дозволяють скористатися багатьма важливими функціями
          «ОЛЛАПП Україна». Веб-сайт може містити посилання на інші веб-сайти.
          Слід зазначити, що «ОЛЛАПП Україна» не контролює файли cookie, які
          використовують зовнішні веб-сайти. Більше інформації про файли cookie
          третіх сторін міститься в їхній власній політиці щодо файлів cookie.
        </p>
        <h3>Прийняття Політики щодо файлів cookie</h3>
        <p>
          Якщо Користувачі використовують Платформу «ОЛЛАПП Україна», це означає
          що вони погоджуються з тим, що «ОЛЛАПП Україна» розміщує файли cookie
          на їхніх комп'ютерах або пристроях для цілей, зазначених у цьому
          документі.
        </p>
        <p className="Body-Bold-2">
          Файли cookie, які використовує «ОЛЛАПП Україна»:
        </p>
        <table>
          <tr>
            <th>Файли cookie</th>
            <th>Опис</th>
            <th>Період зберігання</th>
            <th>Джерело</th>
          </tr>
          <tr>
            <td>_utma</td>
            <td>
              Аналітичний файл cookie для ідентифікації окремих відвідувачів.
              Використовується для збору інформації про окремих відвідувачів для
              цілей статистики й аналізу.
            </td>
            <td>2 роки</td>
            <td>Google (Analytics)</td>
          </tr>
          <tr>
            <td>_utmb</td>
            <td>
              Аналітичний файл cookie для визначення сеансу відвідувача: Google
              Analytics використовує файли cookie для активізації та продовження
              сеансу зв'язку. Якщо Користувач не виконує жодних дій, файл
              оновлюється, й розпочинається новий сеанс.
            </td>
            <td>30 хвилин</td>
            <td>Google (Analytics)</td>
          </tr>
          <tr>
            <td>_utmc</td>
            <td>
              Аналітичний файл cookie для визначення сеансу відвідувача: Google
              Analytics використовує файли cookie для активізації та продовження
              сеансу зв'язку. Якщо Користувач не виконує жодних дій, файл
              оновлюється, й розпочинається новий сеанс.
            </td>
            <td>Сеанс</td>
            <td>Google (Analytics)</td>
          </tr>
          <tr>
            <td>mp_*</td>
            <td>
              Аналітичні файли cookie, які зберігаються MixPanel(третя сторона)
              і додаються в її бібліотеку. Вони зберігають анонімну інформацію
              Користувача, щоб зрозуміти його поведінку.
            </td>
            <td>Політика щодо файлів cookie Mixpanel</td>
            <td>Mixpanel</td>
          </tr>
          <tr>
            <td>Service Worker</td>
            <td>
              Технічний файл cookie, який використовується для покращення
              швидкості веб-сайту.
            </td>
            <td>Невідомо</td>
            <td></td>
          </tr>
          <tr>
            <td>Local Storage</td>
            <td>
              Технічний файл cookie, який використовується для надання
              інформації про мову та міста, з яких Користувачі заходять на
              веб-сайт (анонімно через випадковий ідентифікатор) для
              вдосконалення досвіду Користувачів за допомогою сайту.
            </td>
            <td>Невідомо</td>
            <td></td>
          </tr>
          <tr>
            <td>_cfduid</td>
            <td>
              Технічний файл cookie, який використовується для покращення
              швидкості завантаження сторінок. Він не містить жодної
              ідентифікаційної інформації користувача.
            </td>
            <td>Сеанс</td>
            <td>Appboycdn.com</td>
          </tr>
          <tr>
            <td>Fr</td>
            <td>
              Аналітичний файл cookie третьої сторони, який використовується для
              цілей аналізу.
            </td>
            <td>Політика Facebook щодо файлів cookie</td>
            <td>Facebook</td>
          </tr>
          <tr>
            <td>AWSELB</td>
            <td>
              Технічний файл cookie від постачальника послуг, який розміщує
              сторінку для моніторингу запитів, що надаються через cервер.
            </td>
            <td>1 місяць</td>
            <td>Amazon Web Services</td>
          </tr>
          <tr>
            <td>1P_JAR</td>
            <td>Файли cookie Google analytics для вебаналітики.</td>
            <td>1 тиждень</td>
            <td>Google</td>
          </tr>
          <tr>
            <td>CONSENT</td>
            <td>
              Технічний файл cookie, який запам'ятовує, чи прийняв користувач
              політику щодо файлів cookie.
            </td>
            <td>Постійно</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>_hjIncludedInSample</td>
            <td>
              Аналітичний файл cookie веб-сайту, пов'язаний із відвідуванням
              користувачем платформи.
            </td>
            <td>Сеанс</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>glv_lang</td>
            <td>
              Технічний файл cookie, який встановлює через доступ до IP адреси
              мову, на якій відображатиметься вміст.
            </td>
            <td>Сеанс</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>glv_cart_v2</td>
            <td>
              Технічний файл cookie, який запам'ятовує елементи, обрані через
              платформу.
            </td>
            <td>Сеанс</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>glv_device, ab.storage.deviceId</td>
            <td>
              Технічний файл cookie, що розпізнає пристрій, з якого здійснюється
              доступ до послуги.
            </td>
            <td>Сеанс</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>Ab.storage.sessionId</td>
            <td>
              Технічний файл cookie, який реєструє сеанси користувачів, що
              отримали доступ до платформи.
            </td>
            <td>Постійно</td>
            <td>Власний</td>
          </tr>
          <tr>
            <td>currentTime</td>
            <td>
              Технічний файл cookie, який визначає час, коли користувач
              переглядає сторінки й замовляє послуги через платформу.
            </td>
            <td>Сеанс</td>
            <td>Власний</td>
          </tr>
        </table>
        <h3>Вимкнення файлів cookie </h3>
        <p>
          Якщо Користувач продовжує переглядати сторінки Платформи, це означає
          згоду на використання файлів cookie.
        </p>

        <p>
          Як Користувач ви можете заблокувати або видалити файли cookie «ОЛЛАПП
          Україна» або будь-якого іншого веб-сайту за допомогою свого браузера.
          Способи зробити це відрізняються залежно від браузера, який
          використовує Користувач.
        </p>

        <p>
          Зверніть увагу на те, що «ОЛЛАПП Україна» не може гарантувати належне
          функціонування різних функцій на Платформі, якщо ви зміните
          налаштування файлів cookie.
        </p>

        <p>
          Якщо ви користуєтеся іншим браузером, ви можете дізнатися більше
          інформації про те, як налаштувати приймання файлів cookie за допомогою
          розділу «Допомога» браузера. Якщо вам потрібна допомога з управління
          файлами cookie у вашому браузері, ви можете звернутися до «ОЛЛАПП
          Україна» за допомогою Форми для зворотного зв'язку (в довільному
          форматі), і ми якнайшвидше зв'яжемося з вами.
        </p>
        <h3>Як я можу отримати більше інформації про файли cookie? </h3>
        <p>
          Ми будемо раді відповісти на будь-яке ваше запитання щодо використання
          файлів cookie, яке не розглянуте в цій Політиці. Користувачі можуть
          надсилати свої запитання.
        </p>
      </div>
    </SitePage>
  )
}

export default PrivacyPolicy
