import React, { useEffect, useState } from 'react'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import './PasswordRecovery.sass'
import Button from '../../../ui/Button/Button'
import FieldRender from '../../../utils/forms/render/FieldRender'
import getFormValues from '../../../utils/forms/getFormValues'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import sendRecoveryLink from './functions/sendRecoveryLink'
import { withRouter } from 'react-router'

function PasswordRecovery({ onMessageSend, ...router }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        recovery: {
          field: {
            fieldId: 'recovery',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            label: 'Електронна пошта або телефон',
            placeholder: 'sample@gmail.com або +38(0XX) - XXX - XXXX ',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  console.log(
    '🚀 ~ file: PasswordRecovery.jsx ~ line 14 ~ PasswordRecovery ~ form',
    form
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      form.recovery.value[0] === '+' &&
      (form.recovery.config.type === 'text' ||
        form.recovery.config.type === 'email')
    ) {
      setForm(
        createForm({
          formPattern: {
            recovery: {
              field: {
                fieldId: 'recovery',
                fieldType: 'input',
                inputType: 'phone',
                label: 'Електронна пошта або телефон',
                placeholder: 'sample@gmail.com або +38(0XX) - XXX - XXXX ',
                mask: 'phone',
                required: true,
                value: form.recovery.value,
              },
              render: getFieldRenderObject(),
            },
          },
        })
      )
    } else if (
      form.recovery.value === '+38 (0' &&
      form.recovery.config.type === 'phone'
    ) {
      setForm(
        createForm({
          formPattern: {
            recovery: {
              field: {
                fieldId: 'recovery',
                fieldType: 'input',
                inputType: 'email',
                label: 'Електронна пошта або телефон',
                placeholder: 'sample@gmail.com або +38(0XX) - XXX - XXXX ',
                validationPattern: 'email',
                required: true,
                value: '',
              },
              render: getFieldRenderObject(),
            },
          },
        })
      )
    }
  }, [form])

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      sendRecoveryLink({
        to: form.recovery.value,
        passwordRecoveryPage: router.location.pathname,
      })
        .then(() => onMessageSend(form.recovery.value))
        .catch((e) => {
          console.log(
            '🚀 ~ file: PasswordRecovery.jsx ~ line 95 ~ onSubmit ~ e',
            e
          )
        })
    }
  }

  return (
    <form className="PasswordRecovery-Form" onSubmit={onSubmit}>
      <p className="Text Body-Regular-2">
        Для відновлення паролю введіть електронну пошту або номер телефону
      </p>
      <FieldRender
        key={'recovery'}
        field={{ ...form['recovery'], fieldId: 'recovery' }}
        form={form}
        setForm={setForm}
        showErrors={showErrors}
      />
      <Button
        title="Відновити"
        theme="primary"
        size={40}
        isLoading={isLoading}
        onClick={onSubmit}
        iconPosition="right"
        type="submit"
      />
    </form>
  )
}

export default withRouter(PasswordRecovery)
