import React from 'react'
import './SitePage.sass'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

function SitePage({ head, withFormData = false, children }) {
  return (
    <div className="SitePage">
      <Header white withCaption withFormData={withFormData} />
      {head}
      {children}
      <Footer />
    </div>
  )
}

export default SitePage
