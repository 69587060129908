import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../../App'
import Icon from '../../../ui/Icon/Icon'
import { formatDateForDateChooser } from '../../../utils/date/dateFormat'
import getFormattedValue from '../../HomeSearch/DateChooser/functions/getFormattedValue'
import './HeaderFormData.sass'

function HeaderFormData({ ...router }) {
  const { category, location, dates } = useContext(AuthContext)

  return category.id && location.id ? (
    <div className="HeaderFormData" onClick={() => router.history.push('/')}>
      <Icon name="search" />
      <span className="Caption-Medium Category">{category.title}</span>
      <span className="Caption-Regular Location">{location.title}</span>
      {dates.start && dates.end && (
        <>
          <span className="Ellips" />
          <span className="Caption-Regular Dates">
            {getFormattedValue({
              start: dates.start,
              end: dates.end,
              sameDay: localStorage.getItem('sameDay'),
            })}
          </span>
        </>
      )}
    </div>
  ) : null
}

export default withRouter(HeaderFormData)
