import React from 'react'
import Bar from 'react-password-strength-bar'
import './PasswordStrengthBar.sass'

function PasswordStrengthBar({ password }) {
  const onChangeScore = (score, feedback) => {
    console.log(
      '🚀 ~ file: PasswordStrengthBar.jsx ~ line 7 ~ onChangeScore ~ feedback',
      feedback
    )
    console.log(
      '🚀 ~ file: PasswordStrengthBar.jsx ~ line 7 ~ onChangeScore ~ score',
      score
    )
  }
  return (
    <div className="PasswordStrengthBar">
      <Bar
        className="Bar"
        password={password}
        shortScoreWord="Надто короткий"
        barColors={['#E2E6EB', '#DB3B21', '#FC9403', '#1F78D1', '#1AAA55']}
        scoreWords={[
          'Дуже слабкий',
          'Дуже слабкий',
          'Ненадійний',
          'Надійний',
          'Безпечний',
        ]}
        scoreWordClassName
        minLength={6}
        onChangeScore={onChangeScore}
      />
      <ul className="Text Caption-Regular">
        <li>Мінімум 6 символів;</li>
        <li>
          Містить алфавітні символи верхнього та нижнього регістру (A-Я, а-я);
        </li>
        <li>Містить хоча б одну цифру (0-9);</li>
        <li>Містить хоча б один спеціальний символ (~@#$%^&*()_-+=).</li>
      </ul>
    </div>
  )
}

export default PasswordStrengthBar
