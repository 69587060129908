import { differenceInDays } from 'date-fns'
import { isEmpty, find } from 'lodash'
import defineProductPrice from '../ProductTile/functions/defineProductPrice'

const defineTotalPrice = ({ cart, dates, productsData, spotsByLocation }) => {
  if (
    !isEmpty(cart.products) &&
    !isEmpty(productsData) &&
    !isEmpty(spotsByLocation)
  ) {
    let price = 0
    cart.products.map((p) => {
      const productData = find(productsData, ['productId', p.productId])
      const priceByQuantity =
        p.quantity *
        defineProductPrice({ productData, spotsByLocation, cartProductData: p })
      price += priceByQuantity
    })

    let days = 1
    if (dates.start && dates.end) {
      const calDays = differenceInDays(dates.end, dates.start)
      days = calDays === 0 ? 1 : calDays
      if (calDays && !localStorage.getItem('sameDay')) days += 1
    }

    return price * days
  }
  return 0
}

export default defineTotalPrice
