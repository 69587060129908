export default function handleSignInErrors({
  code,
  message,
  setNBlockSettings,
}) {
  switch (code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Невірні данні для входу',
        content: 'Невірна електронна пошта і/або пароль',
      })

    default:
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: code,
        content: `log for dev: ${message}`,
      })
  }
}
