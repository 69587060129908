import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import CookieNotification from '../CookieNotification/CookieNotification'
import './Footer.sass'
import payments from './payments.svg'

function Footer() {
  return (
    <section className="Footer">
      <CookieNotification />
      <FooterLinkBlock
        title="Допомога"
        content={
          <>
            <Link to="/contacts">Контакти</Link>
            <Link to="/faq">Часті запитання</Link>
          </>
        }
      />
      <FooterLinkBlock
        title="Компанія"
        content={
          <>
            <Link to="/terms-and-conditions">Умови використання</Link>
            <Link to="/privacy-policy">Політика конфіденційності та кукі</Link>
          </>
        }
      />
      <span />
      <FooterLinkBlock
        title="Партнерам"
        content={
          <>
            <span>Співпраця з нами</span>
            <img src={payments} alt="Payments" />
          </>
        }
      />
      <div className="Copyrights">
        <p className="Caption-Regular">
          © OllApp {new Date().getFullYear()}. Прокат будь-якого спортивного
          спорядження — бронюйте у декілька кліків.
        </p>
        <div className="Contacts">
          <div className="Phone Body-Regular-2">
            <Icon name="phone" />
            <span>+38 (067) 796-17-17</span>
          </div>
          <div className="SocialLinks">
            <a href="https://www.instagram.com/ollapp/" target="blank">
              <Icon name="instagram" weight="brand" />
            </a>
            <a href="https://www.facebook.com/ollappp/" target="blank">
              <Icon name="facebook" weight="brand" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

function FooterLinkBlock({ title, content }) {
  return (
    <div className="Footer-LinkBlock">
      <p className="Title Body-Medium-2">{title}</p>
      <div className="Content Body-Regular-2">{content}</div>
    </div>
  )
}

export default Footer
