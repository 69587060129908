import React from 'react'
import './HowItWorks.sass'

export const hTitle = 'Прокат спорядження для активного відпочинку'
export const hDescription = 'Більше 30 пaртнерських пунктів по всій Україні'

function HowItWorks() {
  return (
    <div className="HowItWorks">
      <h1>{hTitle}</h1>
      <p className="Body-Regular-1">{hDescription}</p>
      {/* <Button title="Як це працює?" theme="secondary" size={40} /> */}
    </div>
  )
}

export default HowItWorks
