import React, { useContext } from 'react'
import GenderIcon from './GenderIcon/GenderIcon'
import './ProductTile.sass'
import Button from '../../../ui/Button/Button'
import Img from '../../../ui/Img/Img'
import Checkbox from '../../../ui/Field/Checkbox/Checkbox'
import { AuthContext } from '../../../App'
import { cloneDeep, find } from 'lodash'
import cartProductObject from '../../../components/Cart/cartProductObject'
import setDoc from '../../../utils/db/setDoc'
import defineProductPrice from './functions/defineProductPrice'

function ProductTile({
  productId,
  productCategory,
  avatar,
  frontTitle = 'Product title',
  description,
  options,
  type = 'male',
  spotsByLocation,
  ...productData
}) {
  const { location, cart } = useContext(AuthContext)
  const thisProduct = find(cart.products, { productId, assignment: type })

  const onProductPick = () => {
    let products = cloneDeep(cart.products)
    if (!thisProduct) {
      products.push(
        cartProductObject({
          productId,
          locationId: location.id,
          categoryId: productCategory,
          assignment: type,
          quantity: 1,
          options: options.map((o) => o.id),
        })
      )
    } else {
      products = products.filter((p) => {
        if (p.productId === productId && p.assignment === type) return false
        return true
      })
    }
    updateProducts(products)
  }

  const onProductIncrease = () => {
    const products = cloneDeep(cart.products)
    if (!thisProduct) {
      products.push(
        cartProductObject({
          productId,
          locationId: location.id,
          categoryId: productCategory,
          assignment: type,
          quantity: 1,
          options: options.map((o) => o.id),
        })
      )
    } else {
      products.map((p) => {
        if (p.productId === productId && p.assignment === type) {
          p.quantity += 1
        }
        return p
      })
    }
    updateProducts(products)
  }

  const onProductDecrease = () => {
    if (!thisProduct) return
    let products = cloneDeep(cart.products)
    if (thisProduct.quantity > 1) {
      products.map((p) => {
        if (p.productId === productId && p.assignment === type) {
          p.quantity -= 1
        }
        return p
      })
    } else {
      products = products.filter((p) => {
        if (p.productId === productId && p.assignment === type) return false
        return true
      })
    }
    updateProducts(products)
  }

  const updateProducts = (products) => {
    setDoc({
      path: 'carts',
      docId: cart.id,
      data: { products, created: new Date(), updated: new Date() },
    }).catch((e) => {
      console.log('🚀 ~ file: ProductTile.jsx ~ line 80 ~ e', e)
    })
  }

  const onOptionPick = (optionId) => {
    const products = cloneDeep(cart.products)
    if (!thisProduct) {
      products.push(
        cartProductObject({
          productId,
          locationId: location.id,
          categoryId: productCategory,
          assignment: type,
          quantity: 1,
          options: options.filter((o) => o.id !== optionId).map((o) => o.id),
        })
      )
    } else {
      const productClone = find(products, { productId, assignment: type })
      if (productClone.options.includes(optionId)) {
        productClone.options = productClone.options.filter(
          (o) => o !== optionId
        )
      } else {
        productClone.options.push(optionId)
      }
    }
    updateProducts(products)
  }

  return (
    <div
      className={[
        'ProductTile',
        ...(thisProduct ? ['ProductTile_theme_in_cart'] : ['']),
      ].join(' ')}
    >
      <div className="ProductTile-Head">
        <h3 className="Title">
          <Checkbox
            value={
              thisProduct
                ? options.length === thisProduct.options.length
                  ? 'on'
                  : 'indeterminated'
                : 'off'
            }
            onClick={onProductPick}
          />{' '}
          {frontTitle}
        </h3>
        <GenderIcon type={type} />
      </div>
      <div className="ProductTile-Content">
        <div className="Avatar">
          <Img src={avatar.publicUrl} />
        </div>
        <div className="Options">
          {options.map((o) => (
            <Checkbox
              value={
                !thisProduct || thisProduct.options.includes(o.id)
                  ? 'on'
                  : 'off'
              }
              label={o.title}
              onClick={() => onOptionPick(o.id)}
            />
          ))}
        </div>
      </div>
      <p className="Body-Regular-2">{description}</p>
      <div className="ProductTile-Controlls">
        <QuantityController
          onIncrease={onProductIncrease}
          onDecrease={onProductDecrease}
          counter={thisProduct ? thisProduct.quantity : undefined}
        />
        <div className="Price Body-Medium-2">
          від
          <br />
          {defineProductPrice({ productData, spotsByLocation })} грн / доба
        </div>
      </div>
    </div>
  )
}

function QuantityController({ onIncrease, onDecrease, counter = 1 }) {
  return (
    <div className="QuantityController">
      <Button icon="minus" theme="primary" size={32} onClick={onDecrease} />
      <span className="Quantity Body-Regular-2">{counter}</span>
      <Button icon="plus" theme="primary" size={32} onClick={onIncrease} />
    </div>
  )
}

export default ProductTile
