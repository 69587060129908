import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import SitePage from '../../pages/SitePage/SitePage'
import EditProfile from './EditProfile/EditProfile'
import Orders from './Orders/Orders'
import './Profile.sass'
import ProfileNav from './ProfileNav/ProfileNav'
import PaymentMethods from './PaymentMethods/PaymentMethods'
import { profileLinks } from './ProfileNav/ProfileNav'
import Media from 'react-media'
import TabsController from '../../ui/TabsController/TabsController'

function Profile({ ...router }) {
  const [profileTabs, setProfileTabs] = useState(profileLinks)

  useEffect(() => {
    setProfileTabs(
      profileTabs.map((t) => {
        t.isActive = t.path === router.location.pathname
        return t
      })
    )
  }, [router.location.pathname])

  return (
    <SitePage>
      <section className="Site-Content Profile">
        <Media
          query="(max-width: 1366px)"
          render={() => (
            <TabsController
              tabs={profileTabs.map((l) => {
                l.id = l.path
                return l
              })}
              onClick={(id) => router.history.push(id)}
            />
          )}
        />
        <Media query="(min-width: 1366px)" render={() => <ProfileNav />} />

        <Switch>
          <Route path="/profile/edit-profile" component={EditProfile} />
          <Route path="/profile/orders" component={Orders} />
          <Route path="/profile/payment-methods" component={PaymentMethods} />
          <Route path="/profile" exact>
            <Redirect to="/profile/edit-profile" />
          </Route>
        </Switch>
      </section>
    </SitePage>
  )
}

export default withRouter(Profile)
