import React from 'react'
import './PaymentStatusPage.sass'
import success1 from './assets/success_1.png'
import success2 from './assets/success_2.png'
import failed from './assets/failed_1.png'
import { random } from 'lodash'
import Button from '../../ui/Button/Button'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import { getUrlQueryParams } from '../../utils/queries'

function PaymentState({ status, ...router }) {
  const illustrationSuccess = [success1, success2]

  let content = {
    illustration: '',
    title: '',
    text: '',
    activeButtonText: '',
    activeButtonPath: '',
  }

  const { part, sum } = getUrlQueryParams({
    history: router.history,
    params: ['part', 'sum'],
  })

  switch (status) {
    case 'success':
      content = {
        illustration: illustrationSuccess[random(0, 1)],
        title: `${part ? 'Часткова оплата' : 'Оплата'} пройшла успішно!`,
        text: part
          ? `${sum} грн необхідно буде доплатити на пункті прокату.`
          : 'Деталі замовлення доступні у вашому профілі.',
        activeButtonText: 'Мої замовлення',
        activeButtonPath: '/profile/orders',
      }
      break
    case 'failed':
      content = {
        illustration: failed,
        title: 'Оплата не пройшла (:',
        text: 'Не вдалося обробити платіж. Спробуйте ще раз.',
        activeButtonText: 'Повернутись до оплати',
        activeButtonPath: '/profile/orders',
      }
      break

    default:
      content = {
        illustration: failed,
        title: 'Оплата не пройшла (:',
        text: 'Не вдалося обробити платіж. Спробуйте ще раз.',
        activeButtonText: 'Повернутись до оплати',
        activeButtonPath: '/profile/orders',
      }
      break
  }
  return (
    <section className="PaymentStatusPage">
      <div className="Illustration">
        <img src={content.illustration} alt={`Illustration_${status}`} />
      </div>
      <div className="Content">
        <h3 className="Title">{content.title}</h3>
        <p className="PaymentStatus-Text Body-Regular-1">{content.text}</p>
      </div>
      <div className="Buttons">
        <Media
          query="(min-width: 1366px)"
          render={() => (
            <>
              <Button
                type="navlink"
                path="/"
                title="На головну"
                size={40}
                theme="secondary"
              />
              <Button
                title={content.activeButtonText}
                type="navlink"
                path={content.activeButtonPath}
                size={40}
                theme="primary"
              />
            </>
          )}
        />
        <Media
          query="(max-width: 1366px)"
          render={() => (
            <>
              <Button
                title={content.activeButtonText}
                type="navlink"
                path={content.activeButtonPath}
                size={40}
                theme="primary"
              />
              <Button
                type="navlink"
                path="/"
                title="На головну"
                size={40}
                theme="internal-link"
              />
            </>
          )}
        />
      </div>
    </section>
  )
}

export default withRouter(PaymentState)
